/* ---- Theme Variables ---- */
/* Font */
/* Colors */
/* Transitions */
/* Element radius */
/* ---- Theme Variables ---- */
/* Layout & Helpers */
.mx-n1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem; }

.mx-n2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem; }

.mx-n3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem; }

.mx-n4 {
  margin-left: -1rem;
  margin-right: -1rem; }

.mx-n5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem; }

.dashbg {
  background-color: #2F3742;
  color: #fff; }
  .dashbg ::selection {
    background: rgba(0, 0, 0, 0.5); }

.btn-back i {
  display: inline-block;
  transform: rotate(90deg); }

.dashnew .btn-newwidget {
  top: 70px; }

.dashnew .btn-tasks {
  top: 146px; }

.dash-head {
  position: fixed;
  background: rgba(0, 0, 0, 0.25);
  left: 0;
  top: 0;
  right: 0;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 5;
  font-size: .9em; }
  .dash-head .dash-head-title {
    margin: 0;
    white-space: nowrap;
    align-items: center; }
    .dash-head .dash-head-title > span {
      white-space: nowrap; }

.dash-head-content {
  display: flex;
  align-items: center; }
  .dash-head-content > * {
    padding-right: 20px; }
  .dash-head-content .form-control--inline {
    display: inline-block;
    margin-bottom: 0;
    margin-left: 6px; }

.dash-head-title {
  color: #989FAF;
  display: flex; }
  .dash-head-title .widget-name {
    color: #fff; }

.checker {
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  background-color: #20262d;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%; }
  .checker i {
    transition: .25s ease-out;
    opacity: 0;
    transform: scale(0.2); }
  .checker.checked {
    background-color: #00C853;
    border-color: transparent; }
    .checker.checked i {
      opacity: 1;
      transform: scale(1); }

.scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent; }

.scrollbar::-webkit-scrollbar-track {
  background-color: transparent; }

.scrollbar::-webkit-scrollbar-thumb {
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  transition: .2s all; }
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.5); }

/* Badge */
.badge {
  background-color: #34373E;
  font-size: 14px;
  padding: 10px 14px; }
  .badge p {
    margin: 0;
    line-height: 1.1em; }

.badge--x {
  display: inline-flex;
  align-items: center; }

.badge__close {
  font-size: 10px;
  padding: 6px 12px;
  margin: -6px -12px;
  margin-left: 0;
  color: rgba(255, 255, 255, 0.35); }
  .badge__close:hover {
    color: rgba(255, 255, 255, 0.8); }

.badge--dark {
  background-color: rgba(0, 0, 0, 0.15); }

/* Tooltip */
.tooltip .tooltip-inner {
  font-size: 13px;
  background-color: #1c2127;
  color: #989FAF;
  border: 1px solid rgba(255, 255, 255, 0.1);
  text-align: left; }
  .tooltip .tooltip-inner p {
    margin: 0; }
    .tooltip .tooltip-inner p + p {
      margin-top: 6px; }
  .tooltip .tooltip-inner ul {
    margin: 0;
    margin-top: 6px;
    padding-left: 1em; }
    .tooltip .tooltip-inner ul > li + li {
      margin-top: 3px; }

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #1c2127; }

/* Card */
.card {
  background-color: #1e232a;
  border: none; }

.card--light {
  background-color: rgba(47, 55, 66, 0.5); }

.card-header {
  padding: .5rem 1.2rem;
  border-bottom: none; }
  .card-header h6 {
    font-weight: 400; }

.card-title {
  flex: 1 1 auto; }

.card__actions {
  flex: 0 0 auto; }

.card-body {
  padding: .5rem 1.2rem; }

/* Modal */
.modal .close {
  padding: 22px 20px; }

.modal-actions .btn-text {
  font-size: .9em;
  margin-left: 12px; }

.modal-actions .btn-text-grey {
  color: #5E677B !important;
  border-bottom-color: #5E677B; }

.modal-actions .btn--outline {
  padding-left: 14px;
  padding-right: 14px; }

.dashbg .modal .modal-content {
  border-radius: 0;
  background-color: #404b5a; }

.dashbg .modal .close {
  text-shadow: none;
  color: rgba(255, 255, 255, 0.5); }

.modal-side {
  transition: none; }
  .modal-side.fade {
    opacity: 1; }
    .modal-side.fade .modal-dialog {
      transform: translateX(30%); }
    .modal-side.fade.show {
      transform: translateX(0);
      transition: all 250ms ease-out; }
      .modal-side.fade.show .modal-dialog {
        transition: all 400ms cubic-bezier(0.19, 1, 0.22, 1); }
  .modal-side .modal-header {
    padding: 1rem 2rem;
    flex: 0 0 auto;
    align-items: center; }
  .modal-side .modal-dialog {
    width: 30%;
    margin: 0;
    margin-right: 70%;
    max-width: inherit;
    transition: none; }
    .modal-side .modal-dialog.modal-dialog--faded {
      opacity: .7; }
  .modal-side .modal-content {
    border-radius: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #242b33 !important; }
  .modal-side .modal-body {
    padding-left: 2rem;
    padding-right: 2rem;
    flex: 1 1 auto;
    overflow-y: auto; }
  .modal-side .modal-title {
    text-align: left;
    font-weight: 400;
    color: #626b7f; }
    .modal-side .modal-title .form-control {
      max-width: 200px;
      display: inline-block; }
  .modal-side .modal-footer {
    padding: .5rem 2rem;
    flex: 0 0 auto; }
  .modal-side .nav-tabs--simple {
    font-size: .9em;
    margin-left: 2rem; }
  .modal-side .form-control {
    background-color: #1c2127;
    border-color: rgba(255, 255, 255, 0.3); }
    .modal-side .form-control:focus {
      border-color: rgba(255, 255, 255, 0.6);
      box-shadow: none; }
  .modal-side .modal-body .form .form-control,
  .modal-side .modal-overlay .form .form-control {
    background-color: #1c2127;
    border-color: rgba(255, 255, 255, 0.3); }
    .modal-side .modal-body .form .form-control::placeholder,
    .modal-side .modal-overlay .form .form-control::placeholder {
      color: rgba(152, 159, 175, 0.35); }

.modal-side--short .modal-dialog {
  margin-left: calc(100% - 500px); }

.modal-side--full .modal-dialog {
  width: 90%;
  margin-left: 10%; }

.modal-side--newwidget {
  pointer-events: none; }
  .modal-side--newwidget > * {
    pointer-events: initial; }
  .modal-side--newwidget .modal-dialog {
    width: 30%;
    min-width: 550px;
    margin-left: auto;
    margin-right: auto;
    float: right; }

.modal-side--newtask .modal-dialog,
.modal-side--newdata .modal-dialog {
  width: 40%;
  min-width: 700px;
  margin-left: auto;
  margin-right: auto;
  float: right; }

.modal-open-secondary .modal-side--newtask .modal-dialog {
  transform: translateX(-20px) !important; }

.modal-open-secondary .modal-side--newtask .modal-content {
  background-color: #293039; }

.modal-save .modal-overlay {
  opacity: 1;
  visibility: visible;
  transform: translateY(0); }

.modal-save .modal-header,
.modal-save .modal-body,
.modal-save .modal-footer {
  transition: .3s ease-out;
  filter: blur(3px); }

.modal-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 5;
  padding: 2rem;
  display: flex;
  align-items: center;
  transform: translateY(-20%);
  transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 0;
  visibility: hidden; }

.modal-overlay__body .form--save {
  max-width: 420px;
  margin: 0 auto; }

.modal-overlay__body .btn--wide {
  min-width: 160px; }

/* Color pick element */
.colorpick {
  position: relative;
  max-width: 120px; }
  .colorpick .form-control {
    padding-left: 44px !important; }

.colorpick__swatch {
  position: absolute;
  left: 5px;
  top: 5px;
  width: 28px;
  height: 28px;
  border-radius: 3px;
  cursor: pointer;
  transition: .2s ease-out; }
  .colorpick__swatch:hover {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2); }

/* Color Palette Styles */
.sp-container {
  background-color: #20262d;
  border: none;
  border-radius: 4px;
  box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  font-family: Open Sans, Arial, Helvetica Neue, Helvetica, sans-serif !important; }
  .sp-container input {
    background-color: #20262d;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.2);
    font-family: Open Sans, Arial, Helvetica Neue, Helvetica, sans-serif !important;
    transition: .2s all; }
    .sp-container input:hover {
      border-color: rgba(255, 255, 255, 0.5); }
    .sp-container input:focus {
      box-shadow: none;
      outline: none;
      background-color: #20262d;
      color: #fff; }

.sp-palette .sp-thumb-el {
  flex: 0 0 auto;
  background-image: none;
  margin: 3px;
  border: none;
  width: 30px;
  height: 30px; }
  .sp-palette .sp-thumb-el .sp-thumb-inner {
    border-radius: 50%; }

.sp-palette .sp-thumb-dark.sp-thumb-active .sp-thumb-inner {
  background-image: none; }

.sp-palette-row {
  display: flex; }

.sp-palette
.sp-container button {
  display: inline-block;
  font-family: Open Sans, Arial, Helvetica Neue, Helvetica, sans-serif;
  background-image: none !important;
  background-color: transparent;
  color: #A58F64;
  border: none !important;
  text-shadow: none;
  margin-top: 3px;
  font-family: Open Sans, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 13px;
  transition: all 0.2s linear;
  outline: none; }
  .sp-palette
.sp-container button:hover {
    background-color: transparent;
    color: #2F3742;
    text-shadow: none; }

.sp-palette-container {
  border-right-color: #ECEDEF;
  border-right: none; }

.sp-picker-container {
  width: 250px;
  border-left: none; }

.sp-clear-display {
  border-radius: 5px;
  border-color: rgba(47, 55, 66, 0.35);
  height: 35px !important; }

.sp-cancel {
  display: inline-block;
  font-size: 13px;
  color: rgba(47, 55, 66, 0.35) !important;
  margin: 0;
  margin-top: 3px;
  padding: 2px;
  margin-right: 10px; }
  .sp-cancel:hover {
    color: rgba(47, 55, 66, 0.5) !important;
    text-decoration: none; }

.sp-choose,
.sp-container button.sp-choose {
  border: none !important;
  box-shadow: none !important;
  background-color: #A58F64 !important;
  background-image: none !important;
  text-shadow: none !important;
  font-family: Open Sans, Arial, Helvetica Neue, Helvetica, sans-serif !important;
  color: #fff !important;
  padding: 5px 15px; }
  .sp-choose:hover,
  .sp-container button.sp-choose:hover {
    background-color: #988258 !important;
    color: #fff !important; }

.sp-color,
.sp-hue {
  border: none;
  border-radius: 5px;
  overflow: hidden; }

.sp-clear-enabled .sp-hue {
  top: 44px !important; }

/*---- Touch Slider ---- */
.touchslide {
  display: flex;
  align-items: center; }

.touchslide__slider {
  flex: 1 1 auto; }

.touchslide__liveval {
  flex: 0 0 auto;
  margin-left: 20px;
  min-width: 50px;
  text-align: right; }

.noUi-target {
  border-radius: 20px;
  border: none;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.25); }

.noUi-connect {
  box-shadow: none;
  background-color: #09A709;
  border-radius: 5px; }

.noUi-background {
  background: rgba(47, 55, 66, 0.2);
  box-shadow: none;
  border-radius: 20px; }

.noUi-horizontal {
  height: 4px;
  margin: 18px 12px 22px 12px; }
  .noUi-horizontal .noUi-handle {
    width: 32px;
    height: 32px;
    left: 16px;
    top: -14px;
    border: 6px solid #445060;
    border-radius: 40px;
    background: #09a709;
    cursor: default;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.25s ease; }
    .noUi-horizontal .noUi-handle:hover, .noUi-horizontal .noUi-handle:active {
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
      border: 5px solid #1a1e24; }
    .noUi-horizontal .noUi-handle:before, .noUi-horizontal .noUi-handle:after {
      left: 12px;
      top: 7px;
      display: none; }
    .noUi-horizontal .noUi-handle:after {
      left: 16px; }
  .noUi-horizontal .noUi-tooltip {
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding-bottom: 0;
    bottom: 4px; }
    .noUi-horizontal .noUi-tooltip:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #A58F64;
      position: absolute;
      bottom: -5px;
      left: 50%;
      margin-left: -4px;
      display: none; }
  .noUi-horizontal.activated .noUi-handle {
    background-color: #09A709; }
  .noUi-horizontal.activated .noUi-tooltip {
    color: #fff; }

.touchSlider__val {
  position: absolute;
  margin: 0;
  line-height: 1em;
  right: 0;
  bottom: 17px; }
  .touchSlider__val .form--sufix {
    position: relative;
    max-width: 50px; }
    .touchSlider__val .form--sufix .form__picto {
      position: absolute;
      right: 0;
      top: 2px;
      font-size: .85em;
      opacity: .65; }
  .touchSlider__val .form-control {
    border: none;
    border-bottom: 1px solid rgba(47, 55, 66, 0.08);
    box-shadow: none;
    border-radius: 0;
    height: 24px;
    padding-left: 0;
    padding-right: 20px;
    font-weight: 700;
    text-align: center; }
    .touchSlider__val .form-control:focus {
      border-bottom-color: rgba(47, 55, 66, 0.2); }

/* Dropdown menu */
.dashbg .dropdown-menu {
  background-color: #20262d;
  padding: 6px;
  border-radius: 5px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.12); }
  .dashbg .dropdown-menu .dropdown-item:active {
    background-color: #1e232a;
    color: #fff; }

.dashbg .dropdown-item {
  color: #989FAF;
  border-radius: 5px; }
  .dashbg .dropdown-item:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.1); }
  .dashbg .dropdown-item p {
    margin: 0; }

.dashbg .modal-side .dropdown-menu {
  background-color: #2F3742; }

.dashbg .dropdown-menu--blocks {
  display: none;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  padding: 12px; }
  .dashbg .dropdown-menu--blocks .dropdown-item {
    padding: 20px 8px;
    flex: 1;
    text-align: center; }
    .dashbg .dropdown-menu--blocks .dropdown-item i {
      font-size: 2em; }
  .dashbg .dropdown-menu--blocks p {
    font-size: .95em;
    max-width: 120px;
    margin: 0 auto;
    margin-top: 12px;
    white-space: normal; }

.dashbg .dropdown-menu--blocksnarrow {
  grid-template-columns: 1fr 1fr; }

.dashbg .dropdown.show .dropdown-menu--blocks {
  display: grid; }

.dashbg .dropdown--embed .close {
  display: none; }

.dashbg .dropdown--embed .dropdown-menu {
  display: block;
  position: static;
  transform: none;
  float: none; }

.dashbg .dropdown--embed .dropdown-menu--blocks {
  display: grid; }

.dashbg .dropdown--center .dropdown-menu {
  min-width: 500px;
  left: 50% !important;
  transform: translateX(-50%) !important; }

.dashbg .dropdown--left .dropdown-menu {
  min-width: 500px; }

.dashbg .dropdown--right .dropdown-menu {
  min-width: 500px;
  left: 30% !important;
  transform: translateX(-50%) !important; }

.dashbg .help-block {
  color: #5E677B; }

/* Nav tabs */
.nav-tabs--simple {
  border-bottom-color: rgba(152, 159, 175, 0.15); }
  .nav-tabs--simple .nav-item {
    margin-right: 20px; }
  .nav-tabs--simple .nav-link {
    padding: 8px 0;
    border-radius: 0;
    border: none;
    border-bottom: 3px solid transparent;
    color: #3F4452; }
    .nav-tabs--simple .nav-link:hover {
      background-color: transparent;
      color: #fff; }
    .nav-tabs--simple .nav-link.active {
      background-color: transparent;
      color: #fff;
      border-bottom-color: #fff; }

/* Buttons */
.dashbg .btn, .dashbg .btn-text {
  user-select: none; }

.dashbg .btn-primary {
  background-color: #00C853; }
  .dashbg .btn-primary:hover {
    background-color: #00e25e; }

.dashbg .btn-default {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.35); }
  .dashbg .btn-default:hover {
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    color: #fff; }

.dashbg .btn-simple {
  padding: 10px 12px;
  background-color: #1e232a;
  opacity: .8;
  margin-bottom: 4px; }
  .dashbg .btn-simple:hover {
    background-color: #1a1e24;
    color: #fff;
    opacity: 1; }
  .dashbg .btn-simple img {
    width: 14px; }

.dashbg .btn--outline,
.dashbg .btn--outline:focus {
  box-shadow: inset 0 0 0 1px #09A709;
  color: #09A709; }
  .dashbg .btn--outline:hover,
  .dashbg .btn--outline:focus:hover {
    box-shadow: inset 0 0 0 2px #0abf0a;
    color: #0abf0a; }

.dashbg .btn--withicon {
  display: inline-flex;
  align-items: center; }

.dashbg .btn-text:hover {
  color: #fff !important; }

.dashbg .btn-textmuted {
  color: #3F4452 !important;
  border-bottom-color: rgba(63, 68, 82, 0.35); }
  .dashbg .btn-textmuted:hover {
    color: #fff !important; }

.dashbg .btn-group .btn-default {
  box-shadow: none;
  border: 1px solid #595F68;
  color: rgba(255, 255, 255, 0.4); }
  .dashbg .btn-group .btn-default:hover {
    border-color: rgba(255, 255, 255, 0.65);
    color: rgba(255, 255, 255, 0.7); }
  .dashbg .btn-group .btn-default:not(:first-child) {
    margin-left: -1px; }
  .dashbg .btn-group .btn-default.active, .dashbg .btn-group .btn-default.active:hover {
    border-color: rgba(255, 255, 255, 0.15);
    background-color: rgba(255, 255, 255, 0.25);
    color: #fff; }

/* Grid Content */
.btn-newwidget,
.btn-tasks {
  position: fixed;
  right: 5px;
  top: 10px;
  width: 70px;
  white-space: normal;
  padding: 15px 12px;
  text-align: left;
  z-index: 5; }

.btn-tasks {
  top: 86px; }

.widgets-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px; }
  .widgets-list:focus {
    outline: none; }

.widget-item {
  border: 1px solid rgba(255, 255, 255, 0.25);
  background-color: rgba(0, 0, 0, 0.25);
  min-height: 100px;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.06);
  text-align: center;
  color: rgba(255, 255, 255, 0.65) !important;
  padding-top: 20px; }
  .widget-item:hover {
    border-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0 4px 8px rgba(47, 55, 66, 0.02), inset 0 0 0 1px rgba(255, 255, 255, 0.45);
    cursor: move;
    color: #fff !important; }
  .widget-item i {
    font-size: 2.4em;
    pointer-events: none; }
  .widget-item img {
    width: 82%; }
  .widget-item p {
    padding: 12px;
    margin: 0;
    line-height: 1.1em;
    font-size: 13px; }

.cdash .avatar {
  width: 80px;
  height: 80px;
  background-color: transparent; }
  .cdash .avatar img {
    user-select: none; }

.avatar--square {
  border-radius: 10px; }

.avatar--rounded {
  border-radius: 50%; }

.widget {
  padding: 14px 20px;
  display: flex;
  flex-direction: column;
  height: 100%; }

.widget-head {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-end;
  padding-bottom: 20px; }
  .widget-head > * {
    flex: 1; }
  .widget-head h4 {
    font-size: 1em;
    margin: 0;
    line-height: 1.1em; }

.widget-head__r {
  text-align: right; }

.widget-body {
  flex: 1 1 auto;
  position: relative; }
  .widget-body .chart {
    position: absolute;
    left: 0;
    top: 0;
    width: 100% !important;
    height: 100% !important; }

.widget-val {
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.1em;
  margin: 0; }

.bar {
  display: flex;
  align-items: center;
  margin: 4px 0; }
  .bar > * {
    flex: 0 0 auto; }

.bar__title {
  padding-right: 10px;
  width: 25%; }
  .bar__title p {
    margin: 0;
    font-size: .8em; }

.bar__barbg {
  background-color: rgba(47, 55, 66, 0.35);
  min-height: 35px;
  position: relative;
  width: 50%;
  border-radius: 3px; }

.bar__bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #09A709;
  border-radius: 3px; }

.bar__bar--color2 {
  background-color: #E49923; }

.bar__bar--color3 {
  background-color: #29B8FC; }

.bar__score {
  text-align: right;
  padding-left: 20px;
  width: 25%; }

/* .chart {
    min-height: 240px;
} */
.drag-in-progress .grid-stack-item {
  border: 1px dotted rgba(255, 255, 255, 0.1); }

.modal-headbar {
  flex: 0 0 auto;
  padding: 0 2rem;
  padding-top: 12px; }

.tasks-list {
  margin: 0 -2rem; }

.task-item {
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  transition: .2s all; }
  .task-item:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .task-item:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: #fff; }

.task-item__desc {
  flex: 1 1 auto;
  padding-left: 64px;
  position: relative;
  color: #989FAF;
  min-width: 1px; }
  .task-item__desc::before {
    content: "\e934";
    font-family: "aheadicon";
    position: absolute;
    left: 2rem;
    opacity: .35;
    margin-top: -2px; }
  .task-item__desc:hover {
    color: #fff; }
  .task-item__desc p {
    margin: 0;
    line-height: 1.3em;
    display: block;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; }
  .task-item__desc .task-item__sub {
    font-size: .8em;
    opacity: .6;
    margin-top: 4px; }
  .task-item__desc .badge {
    font-size: 11px;
    font-weight: 700;
    padding: 2px 7px;
    background: rgba(255, 255, 255, 0.4);
    color: rgba(0, 0, 0, 0.65);
    margin-right: 6px; }

.task-item__actions {
  flex: 0 0 auto;
  padding: 0 15px;
  padding-right: 2rem; }
  .task-item__actions .btn-simple {
    background-color: transparent;
    opacity: .25; }
    .task-item__actions .btn-simple:hover {
      background-color: #1a1e24;
      opacity: 1; }

/* Grid Stack */
.grid-stack {
  position: relative;
  top: 10px;
  bottom: 10px;
  min-height: calc(100vh - 20px); }
  .grid-stack > .grid-stack-item > .grid-stack-item-content {
    overflow: initial; }

.grid-stack-item:hover {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
  z-index: 5; }
  .grid-stack-item:hover > .grid-stack-item-content > .grid-actions {
    opacity: 1;
    transform: translateX(0); }

.grid-stack-item:active, .grid-stack-item:focus {
  outline: none; }

.grid-stack-item .grid-stack-item:hover > .grid-stack-item-content {
  transition: .3s ease-out;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.15);
  border-radius: 3px; }

.grid-stack-item .grid-stack-item .grid-actions {
  right: 0;
  top: 0;
  padding-left: 0; }

.grid-stack-item--r > .grid-stack-item-content > .grid-actions {
  transform: translateX(15px);
  right: auto;
  left: -56px; }

.grid-stack-item--r > .grid-stack-item-content .grid-stack-item .grid-actions {
  right: auto;
  left: 0; }

.grid-stack-item--r:hover > .grid-stack-item-content > .grid-actions {
  transform: translateX(0); }

.grid-stack-item--br > .grid-stack-item-content > .grid-actions {
  transform: translateX(15px);
  right: auto;
  left: -56px;
  top: auto;
  bottom: -5px; }

.grid-stack-item--br > .grid-stack-item-content .grid-stack-item .grid-actions {
  right: auto;
  left: 0; }

.grid-stack-item--br:hover > .grid-stack-item-content > .grid-actions {
  transform: translateX(0); }

.grid-stack-item-content .form {
  width: 100%; }

.grid-stack-item-content:active, .grid-stack-item-content:focus {
  outline: none; }

.grid-stack-item-content--center {
  display: flex;
  align-items: center;
  justify-content: center; }

.grid-stack-item-content--bg {
  border-radius: 4px; }

.grid-actions {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: -50px;
  top: 0;
  z-index: 5;
  transition: all 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
  transform: translateX(-15px);
  padding-left: 5px; }

.grid-stack-static .grid-stack-item:hover, .grid-stack-static .grid-stack-item:focus {
  box-shadow: none; }

.grid-stack-static .grid-stack-item .grid-stack-item-content {
  overflow: visible; }

.grid-stack-nested {
  min-height: inherit;
  height: 100%; }

/* Widget editor */
.modal-edit-widget .close {
  position: static;
  margin-top: -3px; }

.modal-edit-widget .modal-header {
  align-items: center; }

.widget-editor {
  display: flex;
  height: 100%; }

.widget-preview {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center; }
  .widget-preview .widget {
    background-color: #2F3742;
    height: auto;
    width: 100%;
    max-width: 460px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); }
  .widget-preview .chart {
    position: static; }
  .widget-preview .selected {
    border: 1px dotted #29B8FC;
    border-radius: 4px;
    position: relative;
    margin: 8px 0; }
  .widget-preview .widget-actions {
    position: absolute;
    left: 108%; }

.widget-options {
  width: 650px;
  flex: 0 0 auto;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  overflow-y: auto; }

.opblock {
  padding: 0 25px; }
  .opblock h4 {
    margin: 0;
    margin-bottom: 20px;
    font-size: 1.1em; }

.btn-newblock {
  border: 1px dotted rgba(255, 255, 255, 0.2);
  display: block;
  margin: 15px 0;
  padding: 25px 12px;
  font-size: 13px;
  font-weight: 400; }
  .btn-newblock:hover {
    color: #29B8FC;
    border: 1px dotted #29B8FC; }

/* Embedded elements */
.element,
.element .chart {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0; }

/* Empty space element */
.dashempty .empty--dashboard {
  display: block; }

.empty--dashboardtop {
  z-index: 20; }

.empty {
  text-align: center; }

.empty__body {
  max-width: 400px;
  margin: 0 auto;
  z-index: -1; }
  .empty__body h5 {
    font-size: 1em;
    margin-bottom: 12px;
    color: #fff; }
  .empty__body p {
    color: #989FAF;
    font-size: .9em; }
  .empty__body .empty__picto {
    font-size: 2.8em;
    margin-bottom: 12px;
    color: #989FAF; }

.empty--dashboard {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none; }
  .empty--dashboard .empty__picto {
    font-size: 4em; }

.empty--light .empty__picto {
  opacity: .35; }

.draggable-mirror {
  z-index: 10000000;
  transition: none;
  display: block; }

/* User list */
.list--players .playeritem:hover {
  background-color: #1c2127;
  cursor: pointer; }

.playeritem {
  background-color: #1e232a;
  border-radius: 2px;
  padding: 12px 20px;
  margin-bottom: 2px;
  align-items: center;
  transition: .2s all;
  font-size: .9em; }
  .playeritem.playeritem--selected {
    background-color: #1a1e24; }
    .playeritem.playeritem--selected .checker {
      background-color: #00C853;
      border-color: transparent; }
      .playeritem.playeritem--selected .checker i {
        opacity: 1;
        transform: scale(1); }
  .playeritem p {
    margin: 0; }
  .playeritem .avatar {
    width: 40px;
    height: 40px;
    margin-right: 16px; }
  .playeritem .checker {
    margin-left: 20px;
    flex: 0 0 auto; }

.playeritem__name {
  flex: 1 1 auto; }

.playeritem__team {
  width: 150px;
  margin-left: 20px;
  flex: 0 0 auto; }

/* Select list */
.list-select {
  height: 300px;
  overflow-y: auto; }

.list__item {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: 10px 20px;
  margin-bottom: 2px;
  align-items: center;
  transition: .2s all;
  font-size: .9em;
  display: flex;
  align-items: center; }
  .list__item:hover {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer; }
  .list__item.list__item--selected {
    background-color: rgba(0, 0, 0, 0.25); }
    .list__item.list__item--selected .checker {
      background-color: #00C853;
      border-color: transparent; }
      .list__item.list__item--selected .checker i {
        opacity: 1;
        transform: scale(1); }
  .list__item .checker {
    flex: 0 0 auto;
    margin-left: 12px; }
  .list__item .list__name {
    flex: 1 1 auto;
    margin: 0;
    font-weight: 700; }

/* Select data */
.data-panes__input {
  width: 120px;
  flex: 0 0 auto; }

.data-panes__content {
  flex: 1 1 auto; }

.inputs {
  margin-left: -2rem;
  margin-top: -5px;
  font-size: .9em;
  padding-right: 30px; }
  .inputs a {
    display: block;
    padding: 5px 12px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    color: #3F4452; }
    .inputs a:hover {
      color: #fff; }
    .inputs a.selected {
      background-color: #1e232a;
      color: #fff; }

/* Steps block */
.badges .badge {
  margin-right: 3px;
  margin-bottom: 3px;
  font-weight: 700; }

.steps--timeline {
  position: relative;
  padding-left: 35px; }
  .steps--timeline .card--step:not(:last-child)::before {
    content: "";
    display: inline-block;
    width: 11px;
    height: 11px;
    border-radius: 3px;
    background-color: #3F4452;
    position: absolute;
    left: -35px;
    top: 20px; }
  .steps--timeline .card--step:not(:last-child)::after {
    content: "";
    display: inline-block;
    width: 1px;
    top: 10px;
    bottom: -25px;
    background-color: #3F4452;
    position: absolute;
    left: -31px;
    top: 25px; }
  .steps--timeline .card--actionstep::before {
    content: "+";
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 3px;
    background-color: transparent;
    box-shadow: inset 0 0 0 1px #3F4452;
    color: #3F4452;
    position: absolute;
    left: -42px;
    top: 20px; }
  .steps--timeline .card--only::after {
    display: none !important; }
  .steps--timeline .card--modifier {
    margin-left: 10px;
    box-shadow: inset 3px 0 0 0 rgba(255, 255, 255, 0.12); }
    .steps--timeline .card--modifier:not(:last-child)::before {
      left: -45px; }
    .steps--timeline .card--modifier:not(:last-child)::after {
      left: -41px; }

.steps .card--step .card-body {
  padding-bottom: 15px; }

.card--icon {
  position: relative;
  padding-left: 40px; }
  .card--icon .card__picto {
    position: absolute;
    left: 20px;
    top: 17px;
    opacity: .15;
    font-size: 1.2em; }

.card--step {
  margin-bottom: 8px; }
  .card--step .card-title {
    font-weight: 700; }

.projection .btn-delete {
  padding-left: 8px;
  padding-right: 8px; }

.derived-field input.form-control {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.derived-field .form-area {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none; }

.derived-field .btn-delete {
  margin-left: -15px; }

.rename-field > div:nth-child(1) {
  position: relative; }
  .rename-field > div:nth-child(1)::after {
    content: '>';
    position: absolute;
    left: 98%;
    opacity: .5;
    top: 36%;
    font-size: 12px;
    line-height: 1em; }

.rename-field .btn-delete {
  margin-left: -15px; }

.daterange .btn-delete {
  margin-top: 1px;
  margin-bottom: 0; }

/* Gridstack overrides */
.grid-stack:focus {
  outline: none; }

.grid-stack > .grid-stack-item > .ui-resizable-se {
  background-image: none;
  transform: none; }
  .grid-stack > .grid-stack-item > .ui-resizable-se::before {
    content: "\e92f";
    font-family: "aheadicon";
    font-size: 9px;
    float: right;
    margin-top: 2px;
    margin-right: -3px;
    color: #989FAF; }

/* Forms */
.dashbg .form-group > label {
  color: rgba(152, 159, 175, 0.5);
  font-size: 13px; }
  .dashbg .form-group > label.form-check-label {
    color: #fff; }

.dashbg .form-control {
  background-color: #1c2127;
  border-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  font-size: .9em;
  padding: 8px 12px;
  min-height: 38px; }
  .dashbg .form-control:hover {
    border-color: rgba(255, 255, 255, 0.5); }
  .dashbg .form-control:focus {
    box-shadow: none;
    background-color: #20262d;
    border-color: rgba(255, 255, 255, 0.6);
    color: #fff; }
  .dashbg .form-control::placeholder {
    color: rgba(152, 159, 175, 0.35); }

.dashbg .form-search {
  position: relative; }
  .dashbg .form-search .form-control {
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    background-color: transparent !important;
    padding-left: 28px !important;
    height: 31px;
    border-bottom-color: rgba(255, 255, 255, 0.3); }
    .dashbg .form-search .form-control:focus {
      background-color: #20262d;
      outline: none;
      box-shadow: none;
      border-bottom-color: rgba(255, 255, 255, 0.65); }
  .dashbg .form-search .form__picto {
    position: absolute;
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
    font-size: .8em;
    opacity: .25; }
  .dashbg .form-search .form-widgetsearch {
    width: 140px; }
    .dashbg .form-search .form-widgetsearch:focus {
      width: 180px;
      transition: .25s ease-out; }

.dashbg .form--style .form-group {
  margin-bottom: 25px; }

.dashbg .custom-control-label {
  font-size: 13px; }
  .dashbg .custom-control-label::before {
    background-color: #16191e;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2);
    top: .1rem; }
  .dashbg .custom-control-label::after {
    top: .1rem; }

.dashbg .custom-switch .custom-control-label::after {
  top: 5px;
  background-color: #5E677B; }

.dashbg .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #1c2127; }

.dashbg .inline-edit {
  border-bottom: 1px dotted rgba(255, 255, 255, 0.35);
  transition: .2s all; }
  .dashbg .inline-edit:hover {
    border-bottom-style: solid;
    border-bottom-color: rgba(255, 255, 255, 0.5);
    cursor: pointer; }
  .dashbg .inline-edit.inline-edit--icon::after {
    content: "\e901";
    font-family: "aheadicon";
    margin-left: 6px;
    font-size: .7em; }

.previewPane {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 15px;
  margin-bottom: 15px;
  padding-bottom: 10px; }

.form--withplus {
  position: relative; }
  .form--withplus .form-control {
    padding-left: 26px !important; }

.drop-formula {
  position: absolute;
  left: -10px;
  top: 7px; }
  .drop-formula .btn {
    background-color: #111418;
    box-shadow: 0 0 0 2px #111418;
    color: rgba(255, 255, 255, 0.5);
    padding: 5px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    line-height: 1em;
    font-size: 12px; }
    .drop-formula .btn:hover {
      color: #fff;
      border-color: #fff;
      cursor: pointer; }

.drop-formulainline .btn-text {
  background: transparent;
  border: none;
  font-size: 13px;
  padding: 8px 0; }

/* Simple List */
.slist__item {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin-bottom: 2px;
  transition: .25s; }
  .slist__item:hover {
    background-color: rgba(0, 0, 0, 0.3); }
    .slist__item:hover .slist__action {
      opacity: 1;
      visibility: visible;
      transform: none; }
  .slist__item .badge {
    padding: 3px 5px;
    margin: 0 4px;
    font-size: .9em;
    font-weight: 700; }

.slist__content {
  flex: 1 1 auto;
  padding: 8px 12px; }
  .slist__content p {
    margin: 0;
    font-size: 14px; }

.slist__action {
  opacity: 0;
  padding: 1px;
  visibility: hidden;
  transition: .25s ease-out;
  transform: translateX(5px); }
  .slist__action .btn-simple {
    background-color: transparent; }
    .slist__action .btn-simple:hover {
      background-color: transparent; }

/* Mentions overrides */
.mentions-input-box {
  background: #1c2127; }
  .mentions-input-box .mentions-autocomplete-list {
    background: #1c2127; }
    .mentions-input-box .mentions-autocomplete-list li {
      display: flex;
      align-items: center;
      background: transparent;
      padding: 10px 12px;
      margin: 0;
      width: auto;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      height: auto;
      line-height: 1.1em;
      transition: .2s all;
      color: #989FAF;
      border-radius: 0;
      font-size: 14px; }
      .mentions-input-box .mentions-autocomplete-list li:hover {
        background: rgba(255, 255, 255, 0.05);
        color: #fff; }
      .mentions-input-box .mentions-autocomplete-list li.active {
        background: rgba(255, 255, 255, 0.05);
        color: #fff; }
      .mentions-input-box .mentions-autocomplete-list li b {
        background-color: transparent; }
  .mentions-input-box .mentions {
    font-size: .9em; }
    .mentions-input-box .mentions > div > strong {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 3px; }
  .mentions-input-box .form-control {
    background: transparent !important; }

/* Select2 overrides */
.select2 {
  width: 100% !important; }
  .select2:focus {
    outline: none; }

.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single {
  background-color: #1c2127 !important;
  border-color: rgba(255, 255, 255, 0.3) !important;
  color: #fff;
  font-size: .9em; }

.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--single {
  border-color: rgba(255, 255, 255, 0.6);
  box-shadow: none;
  outline: none !important; }

.select2-container--default .select2-search--inline .select2-search__field {
  color: #fff;
  padding-left: 8px;
  min-height: 26px; }
  .select2-container--default .select2-search--inline .select2-search__field::placeholder {
    color: rgba(152, 159, 175, 0.35); }

.select2-container--default .select2-results__option {
  font-size: .75em;
  transition: .2s all; }
  .select2-container--default .select2-results__option[aria-selected=true] {
    background-color: rgba(255, 255, 255, 0.07) !important; }
  .select2-container--default .select2-results__option.select2-results__option--highlighted {
    background-color: rgba(255, 255, 255, 0.1) !important; }

.select2-container--default .select2-results__message {
  color: rgba(255, 255, 255, 0.35); }

.select2-container--default .select2-selection__choice {
  background-color: #3F4452 !important;
  border: none !important;
  border-radius: 3px !important;
  font-size: 12px !important;
  padding: 3px 7px !important;
  display: inline-flex;
  flex-direction: row-reverse; }

.select2-container--default .select2-selection__choice__remove {
  margin-right: 0 !important;
  margin-left: 6px !important;
  transition: .2 all !important; }
  .select2-container--default .select2-selection__choice__remove:hover {
    color: #fff !important; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  height: 28px;
  background: transparent;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  outline: none;
  font-size: 13px;
  padding: 2px 6px;
  border-radius: 3px; }
  .select2-container--default .select2-search--dropdown .select2-search__field::placeholder {
    color: rgba(152, 159, 175, 0.35); }

.select2-container--default .select2-selection--single {
  height: 38px; }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 38px; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 38px;
    color: #fff; }

.select2-dropdown {
  background-color: #1c2127 !important;
  border-color: rgba(255, 255, 255, 0.3) !important;
  color: #fff; }

/* Daterange picker overrides */
.dashbg .daterangepicker {
  background-color: #1c2127;
  border: none;
  font-family: Open Sans, Arial, Helvetica Neue, Helvetica, sans-serif; }
  .dashbg .daterangepicker::before {
    border-bottom-color: #1c2127; }
  .dashbg .daterangepicker::after {
    border-bottom-color: #1c2127; }
  .dashbg .daterangepicker .calendar-table {
    background: transparent; }
  .dashbg .daterangepicker th.available span {
    border-color: rgba(255, 255, 255, 0.8);
    transition: .2s all; }
  .dashbg .daterangepicker th.available:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.1); }
    .dashbg .daterangepicker th.available:hover span {
      border-color: #fff; }
  .dashbg .daterangepicker td {
    transition: .2s all; }
    .dashbg .daterangepicker td.off {
      background: transparent; }
    .dashbg .daterangepicker td.available:hover {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.1); }
    .dashbg .daterangepicker td.active {
      background-color: #09A709; }

.dropdown-menu {
  background-color: #20262d;
  padding: 6px;
  border-radius: 5px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.12); }
  .dropdown-menu .dropdown-item:active {
    background-color: #1e232a;
    color: #fff; }

.dropdown-item {
  color: #989FAF;
  border-radius: 5px; }
  .dropdown-item:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.1); }
  .dropdown-item p {
    margin: 0; }

.jsoneditor-menu .jsoneditor-transform, .jsoneditor-menu .jsoneditor-poweredBy {
  display: none; }

.has-gridlines::after {
  content: "";
  pointer-events: none;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.12), transparent 1px), linear-gradient(90deg, transparent calc(100% - 1px), rgba(255, 255, 255, 0.12)), linear-gradient(rgba(255, 255, 255, 0.12), transparent 1px), linear-gradient(transparent calc(100% - 1px), rgba(255, 255, 255, 0.12) 100%);
  background-size: calc(100% / 6) calc(100% / 6);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0; }
