/* ---- Theme Variables ---- */
/* Font */
/* Colors */
/* Transitions */
/* Element radius */
/*---- Race Track ----*/
.racepage--horses .racetrack {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #222; }
  .racepage--horses .racetrack > * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; }

.racepage--horses .racetrack__head {
  height: 150px;
  flex: 0 0 auto;
  position: relative;
  overflow: hidden; }
  .racepage--horses .racetrack__head .btn-back {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 6;
    color: rgba(0, 0, 0, 0.55);
    padding: 12px; }
    .racepage--horses .racetrack__head .btn-back i {
      display: inline-block;
      transform: rotate(180deg);
      position: relative;
      top: -1px; }
    .racepage--horses .racetrack__head .btn-back:hover {
      color: #111; }

.racepage--horses .racetrack__title {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 10px;
  text-align: center; }
  .racepage--horses .racetrack__title h1 {
    font-size: 1.1em;
    margin: 0;
    color: #111; }

.racepage--horses .racetrack__logo {
  position: absolute;
  left: 80px;
  top: 38%;
  transform: translateY(-50%); }
  .racepage--horses .racetrack__logo img {
    width: 100px;
    height: auto; }

.racepage--horses .ahead-logo {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2; }
  .racepage--horses .ahead-logo img {
    width: 100px;
    height: auto; }

.racepage--horses .racetrack__flag {
  position: absolute;
  right: -50px;
  bottom: 0;
  transition: all 1.6s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .racepage--horses .racetrack__flag img {
    height: 100px;
    width: auto; }

.racepage--horses .racetrack__bg {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  background-repeat: repeat-x;
  background-size: auto 100%;
  transition: left 5s ease; }

.racepage--horses .racetrack__track {
  flex: 1 1 auto;
  position: relative;
  z-index: 1; }

.racepage--horses .list-racers {
  position: absolute;
  margin: 0;
  z-index: 10;
  left: 72px;
  top: 0;
  height: 100%;
  right: 0;
  display: flex;
  flex-direction: column; }
  .racepage--horses .list-racers > li {
    display: inline-block;
    position: relative;
    flex: 1 1 auto;
    transition: 5s ease;
    left: 0;
    width: 70px;
    transform: translateX(-100%);
    max-height: calc(100% / 7); }
    .racepage--horses .list-racers > li:hover {
      z-index: 10; }
    .racepage--horses .list-racers > li svg {
      width: 100% !important;
      height: auto !important; }
    .racepage--horses .list-racers > li .bike::before {
      content: "";
      display: inline-block;
      position: absolute;
      bottom: 79%;
      right: 71%;
      width: 30px;
      height: 30px;
      background-image: url(../images/winner.svg);
      background-repeat: no-repeat;
      background-size: contain;
      transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transform-origin: center;
      transform: scale(0);
      opacity: 0; }
    .racepage--horses .list-racers > li.winner .bike::before {
      transform: scale(1);
      opacity: 1;
      animation: pulseWin linear 4s;
      animation-iteration-count: infinite;
      transform-origin: 50% 50%;
      transition-delay: .2s; }
    .racepage--horses .list-racers > li.winner .list-racers__name {
      left: 20%;
      top: 26%;
      background-color: #FFD15D;
      color: #222; }
  .racepage--horses .list-racers .bike {
    position: relative; }

@keyframes pulseWin {
  0% {
    transform: scale(1) translateY(0); }
  50% {
    transform: scale(1.05) translateY(6px); }
  100% {
    transform: scale(1) translateY(0); } }

.racepage--horses .list-racers__name {
  margin: 0;
  position: absolute;
  left: 108%;
  top: 22%;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  padding: 0 2px;
  display: inline-block;
  background-color: rgba(89, 66, 62, 0.85);
  transition: 1s ease;
  white-space: nowrap;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis; }

.racepage--horses .list-racers__score {
  position: absolute;
  padding: 8px 10px;
  line-height: 1em;
  left: 50%;
  bottom: 85%;
  transform: translateX(-50%) translateY(10px);
  font-size: 13px;
  font-weight: 700;
  background-color: rgba(89, 66, 62, 0.85);
  color: #fff;
  border-radius: 3px;
  transition: .2s ease-out;
  opacity: 0; }

.racepage--horses .racetrack--labelsback .list-racers__name {
  left: -2%;
  transform: translateX(-100%); }

.racepage--horses .bike:hover .list-racers__score {
  opacity: 1;
  transform: translateX(-50%) translateY(0); }

.racepage--horses .racetrack--more-than-7 .list-racers > li {
  max-height: 7.14285714286%; }
  .racepage--horses .racetrack--more-than-7 .list-racers > li .bike {
    position: absolute;
    bottom: 30%; }

.racepage--horses .racetrack--more-than-14 .track:not(.track--single) {
  display: none; }

.racepage--horses .racetrack--more-than-14 .track--single {
  display: block; }
  .racepage--horses .racetrack--more-than-14 .track--single .track__start {
    left: 63px; }
  .racepage--horses .racetrack--more-than-14 .track--single .track__finish {
    width: 80px; }

.racepage--horses .racetrack--more-than-14 .list-racers > li .bike {
  position: absolute;
  bottom: 25%; }

.racepage--horses .racetrack--self .list-racers > li {
  max-height: 5%; }
  .racepage--horses .racetrack--self .list-racers > li .bike {
    position: absolute;
    bottom: 0; }

.racepage--horses .tracks {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px); }

.racepage--horses .track {
  background: #98a437;
  background: -moz-linear-gradient(top, #98a437 0%, #8ba034 100%);
  background: -webkit-linear-gradient(top, #98a437 0%, #8ba034 100%);
  background: linear-gradient(to bottom, #98a437 0%, #8ba034 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#98a437', endColorstr='#8ba034',GradientType=0 );
  position: relative;
  flex: 1 1 auto; }
  .racepage--horses .track + .track {
    /* box-shadow: inset 0 3px 0 rgba(255,255,255,.2); */ }
    .racepage--horses .track + .track .track__finish {
      top: 3px; }

.racepage--horses .track__label {
  position: absolute;
  right: 15px;
  top: 5px;
  color: #fff;
  font-size: 1.8em;
  line-height: 1em;
  opacity: .5;
  letter-spacing: -0.035em;
  transition: 1.5s ease-out;
  margin: 0;
  z-index: 3; }

.racepage--horses .track__start {
  position: absolute;
  left: 50px;
  top: 0;
  height: 100%; }
  .racepage--horses .track__start img {
    height: 100%;
    width: auto; }

.racepage--horses .track__finish {
  position: absolute;
  right: -90px;
  top: 0;
  width: 90px;
  height: 100%;
  background-image: url(../images/track-finish-horses.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: auto 100%;
  transition: all 2.5s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .racepage--horses .track__finish img {
    height: 100%;
    width: auto; }

.racepage--horses .track__line {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-position: 0 0;
  background-size: 200% auto;
  background-repeat: repeat-x;
  opacity: .55; }

.racepage--horses .track__grass {
  background-image: url(../images/track-grass.png);
  background-repeat: repeat-x;
  background-size: 100% auto;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0; }

.racepage--horses .tracks .track:nth-child(odd) .track__grass {
  background-image: url(../images/track-grass-alt.png); }

.racepage--horses .track__top,
.racepage--horses .track__bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  height: 4px;
  background-size: auto 100%; }

.racepage--horses .track__bottom {
  top: auto;
  bottom: 0; }

.racepage--horses .track__logo {
  position: absolute;
  left: 10%;
  right: 10%;
  top: 10%;
  bottom: 10%; }
  .racepage--horses .track__logo img {
    position: absolute;
    max-width: 90%;
    max-height: 90%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: .1; }

.racepage--horses .track--single {
  display: none; }
  .racepage--horses .track--single .track__start {
    width: 12px;
    background-color: rgba(255, 255, 255, 0.35);
    position: absolute;
    left: 60px;
    top: 0;
    height: 100%; }
  .racepage--horses .track--single .track__finish {
    background-image: url(../images/track-finish-single-invert.png);
    background-repeat: repeat-y;
    background-position: top left;
    background-size: 14px auto;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    right: -130px;
    top: 0;
    height: 100%;
    width: 130px; }
  .racepage--horses .track--single .track__line {
    right: 0;
    left: 0;
    background-position: right top;
    background-size: auto 100%; }
  .racepage--horses .track--single .track__label {
    top: 12px; }
  .racepage--horses .track--single .track__grass {
    background-image: url(../images/track-grass-single.png);
    background-repeat: repeat;
    background-size: auto 300px; }

.racepage--horses .track-dirt-single {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 2800px;
  background-size: auto 100%; }

.racepage--horses .showfinish .racetrack__flag {
  right: 10px;
  transition-delay: .6s; }

.racepage--horses .showfinish .track__finish {
  right: 0 !important; }

.racepage--horses .showfinish .track__label {
  right: 90px; }

.racepage--horses .scoreboard__head {
  background: #3b7a5f;
  background: -moz-linear-gradient(top, #3b7a5f 0%, #2c5b48 100%);
  background: -webkit-linear-gradient(top, #3b7a5f 0%, #2c5b48 100%);
  background: linear-gradient(to bottom, #3b7a5f 0%, #2c5b48 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b7a5f', endColorstr='#2c5b48',GradientType=0 );
  position: relative; }
  .racepage--horses .scoreboard__head:before {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 23px;
    width: 100%;
    background-image: url(../images/scoreboard-grass.png);
    background-repeat: repeat-x;
    background-position: bottom left;
    background-size: auto 100%;
    opacity: .5; }

.racepage--horses .list-scores > li:not(.list-scores__head) {
  border-radius: 6px; }
  .racepage--horses .list-scores > li:not(.list-scores__head).active {
    transform: rotateX(0) translateY(0) scale(1); }

.racepage--horses .list-scores > li.list-scores__head {
  background-image: none;
  background-color: #183429; }

.racepage--horses .racepage .tooltip {
  margin-top: 15px; }

@media screen and (min-width: 1400px) {
  .racepage--horses .track__label {
    font-size: 2.5em; } }

@media screen and (max-width: 767px) {
  .racepage--horses .racetrack__logo {
    left: 10px;
    background-color: rgba(255, 255, 255, 0.15);
    padding: 0;
    top: 55%;
    border-radius: 4px; }
    .racepage--horses .racetrack__logo img {
      width: 65px; }
  .racepage--horses .racetrack__title h1 {
    font-size: .9em; }
  .racepage--horses .ahead-logo {
    right: 5px;
    top: 5px; }
    .racepage--horses .ahead-logo img {
      width: 76px; }
  .racepage--horses .list-racers {
    left: 55px; }
    .racepage--horses .list-racers > li {
      width: 55px; }
  .racepage--horses .track__start {
    left: 26px; }
  .racepage--horses .track__finish {
    width: 80px;
    right: -80px; }
  .racepage--horses .track__label {
    font-size: 1.2em;
    top: 8px;
    right: 5px; }
  .racepage--horses .showfinish .track__label {
    right: 75px; }
  .racepage--horses .showfinish.racetrack--more-than-14 .track__label {
    right: 45px; }
  .racepage--horses .racetrack--more-than-14 .track--single .track__start {
    left: 45px;
    width: 8px; }
  .racepage--horses .racetrack--more-than-14 .track--single .track__finish {
    background-size: 6px auto;
    width: 38px; }
  .racepage--horses .list-scores > li.goldalt::before, .racepage--horses .list-scores > li.silveralt::before, .racepage--horses .list-scores > li.bronzealt::before {
    width: 24px;
    height: 24px;
    left: -12px;
    transform: skew(15deg); }
  .racepage--horses .modal-scoreboard .modal-content {
    padding-left: 8px;
    padding-right: 8px; } }

@media screen and (min-width: 768px) and (min-height: 600px) {
  .racepage--horses .racetrack--larger-horses .track__start {
    left: 57px; }
  .racepage--horses .racetrack--larger-horses .track__finish {
    width: 90px;
    right: -90px; }
  .racepage--horses .racetrack--larger-horses .list-racers {
    left: 82px; }
    .racepage--horses .racetrack--larger-horses .list-racers > li {
      width: 80px; }
    .racepage--horses .racetrack--larger-horses .list-racers .bike {
      position: absolute;
      bottom: 38%; }
  .racepage--horses .racetrack--largest-horses .track__start {
    left: 74px; }
  .racepage--horses .racetrack--largest-horses .track__finish {
    width: 115px;
    right: -115px; }
  .racepage--horses .racetrack--largest-horses .list-racers {
    left: 102px; }
    .racepage--horses .racetrack--largest-horses .list-racers > li {
      width: 100px; }
    .racepage--horses .racetrack--largest-horses .list-racers .bike {
      position: absolute;
      bottom: 36%; }
  .racepage--horses .racetrack--largest-horses .list-racers__name {
    font-size: .9em; }
  .racepage--horses .racetrack--ultralarge-horses .track__start {
    left: 95px; }
  .racepage--horses .racetrack--ultralarge-horses .track__finish {
    width: 160px;
    right: -160px; }
  .racepage--horses .racetrack--ultralarge-horses .list-racers {
    left: 126px; }
    .racepage--horses .racetrack--ultralarge-horses .list-racers > li {
      width: 165px; }
    .racepage--horses .racetrack--ultralarge-horses .list-racers .bike {
      position: absolute;
      bottom: 36%; }
  .racepage--horses .racetrack--ultralarge-horses .list-racers__name {
    font-size: .95em; }
  .racepage--horses .racetrack--smaller-horses .track__start {
    left: 42px; }
  .racepage--horses .racetrack--smaller-horses .track__finish {
    width: 80px;
    right: -80px; }
  .racepage--horses .racetrack--smaller-horses .list-racers {
    left: 62px; }
    .racepage--horses .racetrack--smaller-horses .list-racers > li {
      width: 60px; }
  .racepage--horses .racetrack--smallest-horses .track__start {
    left: 33px; }
  .racepage--horses .racetrack--smallest-horses .track__finish {
    width: 70px;
    right: -70px; }
  .racepage--horses .racetrack--smallest-horses .list-racers {
    left: 50px; }
    .racepage--horses .racetrack--smallest-horses .list-racers > li {
      width: 48px; }
  .racepage--horses .racetrack--more-than-14.racetrack--smallest-horses .track--single .track__start {
    width: 8px;
    left: 55px; }
  .racepage--horses .racetrack--more-than-14.racetrack--smallest-horses .track--single .track__finish {
    width: 35px;
    background-size: 8px auto; }
  .racepage--horses .racetrack--more-than-14.racetrack--smaller-horses .track--single .track__start {
    width: 8px;
    left: 60px; }
  .racepage--horses .racetrack--more-than-14.racetrack--smaller-horses .track--single .track__finish {
    width: 45px;
    background-size: 8px auto; }
  .racepage--horses .racetrack--more-than-14.racetrack--larger-horses .track--single .track__start {
    left: 70px; }
  .racepage--horses .racetrack--more-than-14.racetrack--larger-horses .track--single .track__finish {
    width: 70px; }
  .racepage--horses .racetrack--more-than-14.racetrack--largest-horses .track--single .track__start {
    left: 90px; }
  .racepage--horses .racetrack--more-than-14.racetrack--largest-horses .track--single .track__finish {
    width: 85px; }
  .racepage--horses .racetrack--more-than-14.racetrack--ultralarge-horses .track--single .track__start {
    left: 114px; }
  .racepage--horses .racetrack--more-than-14.racetrack--ultralarge-horses .track--single .track__finish {
    width: 94px; }
  .racepage--horses .showfinish.racetrack--larger-horses .track__label {
    right: 80px; }
  .racepage--horses .showfinish.racetrack--largest-horses .track__label {
    right: 100px; }
  .racepage--horses .showfinish.racetrack--ultralarge-horses .track__label {
    right: 110px; }
  .racepage--horses .showfinish.racetrack--smaller-horses .track__label {
    right: 60px; }
  .racepage--horses .showfinish.racetrack--smallest-horses .track__label {
    right: 60px; } }

@media screen and (max-width: 992px) and (max-height: 450px) {
  .racepage--horses .racetrack__head {
    height: 68px; }
    .racepage--horses .racetrack__head .btn-back {
      left: 60px; }
  .racepage--horses .racetrack__logo img {
    width: 56px; }
  .racepage--horses .list-racers {
    left: 55px; }
    .racepage--horses .list-racers > li {
      width: 55px; }
  .racepage--horses .track + .track {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.8); }
  .racepage--horses .tracks {
    height: calc(100vh - 68px); }
  .racepage--horses .track__start {
    left: 26px; }
  .racepage--horses .track__finish {
    width: 75px;
    right: -75px; }
  .racepage--horses .racetrack--more-than-14 .track--single .track__start {
    left: 39px;
    width: 8px; }
  .racepage--horses .racetrack--more-than-14 .track--single .track__finish {
    background-size: 6px auto;
    width: 62px; }
  .racepage--horses .racepage__ctrl {
    top: 35px;
    right: 5px; }
    .racepage--horses .racepage__ctrl .form .form-group {
      vertical-align: top; }
    .racepage--horses .racepage__ctrl .form .form-control {
      height: 28px !important;
      padding-top: 3px;
      padding-bottom: 3px; }
    .racepage--horses .racepage__ctrl .btn-outline {
      padding-top: 8px;
      padding-bottom: 8px;
      min-height: 28px; }
  .racepage--horses .track__label {
    top: 3px;
    font-size: 1.2em; }
  .racepage--horses .racetrack--more-than-14 .track__label {
    top: 7px; }
  .racepage--horses .showfinish .track__label {
    right: 60px; } }
