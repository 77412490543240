/* ---- Theme Variables ---- */
/* Font */
/* Colors */
/* Transitions */
/* Element radius */
/* ---- Theme Variables ---- */
/* Daterange picker overrides */
.daterangepicker {
  background-color: #1a1e24;
  border: none;
  border-radius: 3px;
  font-family: Open Sans, Arial, Helvetica Neue, Helvetica, sans-serif;
  padding: 6px; }
  .daterangepicker::after {
    border-bottom-color: #1a1e24; }
  .daterangepicker::before {
    border-bottom-color: #1a1e24; }
  .daterangepicker.show-ranges.ltr .drp-calendar.left {
    border-left: 1px solid rgba(255, 255, 255, 0.1); }
  .daterangepicker .calendar-table {
    background-color: transparent;
    border: none; }
    .daterangepicker .calendar-table .prev,
    .daterangepicker .calendar-table .next {
      transition: .2s all; }
      .daterangepicker .calendar-table .prev span,
      .daterangepicker .calendar-table .next span {
        border-color: rgba(255, 255, 255, 0.5);
        transition: .2s all; }
      .daterangepicker .calendar-table .prev:hover,
      .daterangepicker .calendar-table .next:hover {
        background-color: rgba(255, 255, 255, 0.08); }
        .daterangepicker .calendar-table .prev:hover span,
        .daterangepicker .calendar-table .next:hover span {
          border-color: #fff; }
  .daterangepicker select.monthselect, .daterangepicker select.yearselect {
    background: transparent;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12); }
    .daterangepicker select.monthselect option, .daterangepicker select.yearselect option {
      color: #2F3742; }
  .daterangepicker td {
    border-radius: 3px;
    transition: .2s all; }
    .daterangepicker td.available:hover {
      background-color: rgba(255, 255, 255, 0.08);
      color: #fff; }
    .daterangepicker td.start-date {
      border-radius: 16px 0 0 16px; }
    .daterangepicker td.end-date {
      border-radius: 0 16px 16px 0; }
    .daterangepicker td.off {
      background-color: transparent;
      color: rgba(255, 255, 255, 0.35); }
      .daterangepicker td.off.in-range {
        background-color: rgba(9, 167, 9, 0.18); }
    .daterangepicker td.in-range {
      background-color: rgba(9, 167, 9, 0.25);
      color: #fff; }
    .daterangepicker td.active, .daterangepicker td.active:hover {
      background-color: #09A709; }
  .daterangepicker .ranges li {
    color: rgba(255, 255, 255, 0.65);
    border-radius: 3px;
    transition: .2s all; }
    .daterangepicker .ranges li:hover {
      background-color: rgba(0, 0, 0, 0.15);
      color: #fff; }
    .daterangepicker .ranges li.active {
      background-color: #09A709; }
