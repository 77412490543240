.grid-stack.grid-stack-1 > .grid-stack-item {
  min-width: 100%; }
  .grid-stack.grid-stack-1 > .grid-stack-item[data-gs-width='1'] {
    width: 100%; }
  .grid-stack.grid-stack-1 > .grid-stack-item[data-gs-x='1'] {
    left: 100%; }
  .grid-stack.grid-stack-1 > .grid-stack-item[data-gs-min-width='1'] {
    min-width: 100%; }
  .grid-stack.grid-stack-1 > .grid-stack-item[data-gs-max-width='1'] {
    max-width: 100%; }

.grid-stack.grid-stack-2 > .grid-stack-item {
  min-width: 50%; }
  .grid-stack.grid-stack-2 > .grid-stack-item[data-gs-width='1'] {
    width: 50%; }
  .grid-stack.grid-stack-2 > .grid-stack-item[data-gs-x='1'] {
    left: 50%; }
  .grid-stack.grid-stack-2 > .grid-stack-item[data-gs-min-width='1'] {
    min-width: 50%; }
  .grid-stack.grid-stack-2 > .grid-stack-item[data-gs-max-width='1'] {
    max-width: 50%; }
  .grid-stack.grid-stack-2 > .grid-stack-item[data-gs-width='2'] {
    width: 100%; }
  .grid-stack.grid-stack-2 > .grid-stack-item[data-gs-x='2'] {
    left: 100%; }
  .grid-stack.grid-stack-2 > .grid-stack-item[data-gs-min-width='2'] {
    min-width: 100%; }
  .grid-stack.grid-stack-2 > .grid-stack-item[data-gs-max-width='2'] {
    max-width: 100%; }

.grid-stack.grid-stack-3 > .grid-stack-item {
  min-width: 33.33333%; }
  .grid-stack.grid-stack-3 > .grid-stack-item[data-gs-width='1'] {
    width: 33.33333%; }
  .grid-stack.grid-stack-3 > .grid-stack-item[data-gs-x='1'] {
    left: 33.33333%; }
  .grid-stack.grid-stack-3 > .grid-stack-item[data-gs-min-width='1'] {
    min-width: 33.33333%; }
  .grid-stack.grid-stack-3 > .grid-stack-item[data-gs-max-width='1'] {
    max-width: 33.33333%; }
  .grid-stack.grid-stack-3 > .grid-stack-item[data-gs-width='2'] {
    width: 66.66667%; }
  .grid-stack.grid-stack-3 > .grid-stack-item[data-gs-x='2'] {
    left: 66.66667%; }
  .grid-stack.grid-stack-3 > .grid-stack-item[data-gs-min-width='2'] {
    min-width: 66.66667%; }
  .grid-stack.grid-stack-3 > .grid-stack-item[data-gs-max-width='2'] {
    max-width: 66.66667%; }
  .grid-stack.grid-stack-3 > .grid-stack-item[data-gs-width='3'] {
    width: 100%; }
  .grid-stack.grid-stack-3 > .grid-stack-item[data-gs-x='3'] {
    left: 100%; }
  .grid-stack.grid-stack-3 > .grid-stack-item[data-gs-min-width='3'] {
    min-width: 100%; }
  .grid-stack.grid-stack-3 > .grid-stack-item[data-gs-max-width='3'] {
    max-width: 100%; }

.grid-stack.grid-stack-4 > .grid-stack-item {
  min-width: 25%; }
  .grid-stack.grid-stack-4 > .grid-stack-item[data-gs-width='1'] {
    width: 25%; }
  .grid-stack.grid-stack-4 > .grid-stack-item[data-gs-x='1'] {
    left: 25%; }
  .grid-stack.grid-stack-4 > .grid-stack-item[data-gs-min-width='1'] {
    min-width: 25%; }
  .grid-stack.grid-stack-4 > .grid-stack-item[data-gs-max-width='1'] {
    max-width: 25%; }
  .grid-stack.grid-stack-4 > .grid-stack-item[data-gs-width='2'] {
    width: 50%; }
  .grid-stack.grid-stack-4 > .grid-stack-item[data-gs-x='2'] {
    left: 50%; }
  .grid-stack.grid-stack-4 > .grid-stack-item[data-gs-min-width='2'] {
    min-width: 50%; }
  .grid-stack.grid-stack-4 > .grid-stack-item[data-gs-max-width='2'] {
    max-width: 50%; }
  .grid-stack.grid-stack-4 > .grid-stack-item[data-gs-width='3'] {
    width: 75%; }
  .grid-stack.grid-stack-4 > .grid-stack-item[data-gs-x='3'] {
    left: 75%; }
  .grid-stack.grid-stack-4 > .grid-stack-item[data-gs-min-width='3'] {
    min-width: 75%; }
  .grid-stack.grid-stack-4 > .grid-stack-item[data-gs-max-width='3'] {
    max-width: 75%; }
  .grid-stack.grid-stack-4 > .grid-stack-item[data-gs-width='4'] {
    width: 100%; }
  .grid-stack.grid-stack-4 > .grid-stack-item[data-gs-x='4'] {
    left: 100%; }
  .grid-stack.grid-stack-4 > .grid-stack-item[data-gs-min-width='4'] {
    min-width: 100%; }
  .grid-stack.grid-stack-4 > .grid-stack-item[data-gs-max-width='4'] {
    max-width: 100%; }

.grid-stack.grid-stack-5 > .grid-stack-item {
  min-width: 20%; }
  .grid-stack.grid-stack-5 > .grid-stack-item[data-gs-width='1'] {
    width: 20%; }
  .grid-stack.grid-stack-5 > .grid-stack-item[data-gs-x='1'] {
    left: 20%; }
  .grid-stack.grid-stack-5 > .grid-stack-item[data-gs-min-width='1'] {
    min-width: 20%; }
  .grid-stack.grid-stack-5 > .grid-stack-item[data-gs-max-width='1'] {
    max-width: 20%; }
  .grid-stack.grid-stack-5 > .grid-stack-item[data-gs-width='2'] {
    width: 40%; }
  .grid-stack.grid-stack-5 > .grid-stack-item[data-gs-x='2'] {
    left: 40%; }
  .grid-stack.grid-stack-5 > .grid-stack-item[data-gs-min-width='2'] {
    min-width: 40%; }
  .grid-stack.grid-stack-5 > .grid-stack-item[data-gs-max-width='2'] {
    max-width: 40%; }
  .grid-stack.grid-stack-5 > .grid-stack-item[data-gs-width='3'] {
    width: 60%; }
  .grid-stack.grid-stack-5 > .grid-stack-item[data-gs-x='3'] {
    left: 60%; }
  .grid-stack.grid-stack-5 > .grid-stack-item[data-gs-min-width='3'] {
    min-width: 60%; }
  .grid-stack.grid-stack-5 > .grid-stack-item[data-gs-max-width='3'] {
    max-width: 60%; }
  .grid-stack.grid-stack-5 > .grid-stack-item[data-gs-width='4'] {
    width: 80%; }
  .grid-stack.grid-stack-5 > .grid-stack-item[data-gs-x='4'] {
    left: 80%; }
  .grid-stack.grid-stack-5 > .grid-stack-item[data-gs-min-width='4'] {
    min-width: 80%; }
  .grid-stack.grid-stack-5 > .grid-stack-item[data-gs-max-width='4'] {
    max-width: 80%; }
  .grid-stack.grid-stack-5 > .grid-stack-item[data-gs-width='5'] {
    width: 100%; }
  .grid-stack.grid-stack-5 > .grid-stack-item[data-gs-x='5'] {
    left: 100%; }
  .grid-stack.grid-stack-5 > .grid-stack-item[data-gs-min-width='5'] {
    min-width: 100%; }
  .grid-stack.grid-stack-5 > .grid-stack-item[data-gs-max-width='5'] {
    max-width: 100%; }

.grid-stack.grid-stack-6 > .grid-stack-item {
  min-width: 16.66667%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-width='1'] {
    width: 16.66667%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-x='1'] {
    left: 16.66667%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-min-width='1'] {
    min-width: 16.66667%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-max-width='1'] {
    max-width: 16.66667%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-width='2'] {
    width: 33.33333%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-x='2'] {
    left: 33.33333%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-min-width='2'] {
    min-width: 33.33333%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-max-width='2'] {
    max-width: 33.33333%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-width='3'] {
    width: 50%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-x='3'] {
    left: 50%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-min-width='3'] {
    min-width: 50%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-max-width='3'] {
    max-width: 50%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-width='4'] {
    width: 66.66667%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-x='4'] {
    left: 66.66667%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-min-width='4'] {
    min-width: 66.66667%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-max-width='4'] {
    max-width: 66.66667%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-width='5'] {
    width: 83.33333%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-x='5'] {
    left: 83.33333%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-min-width='5'] {
    min-width: 83.33333%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-max-width='5'] {
    max-width: 83.33333%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-width='6'] {
    width: 100%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-x='6'] {
    left: 100%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-min-width='6'] {
    min-width: 100%; }
  .grid-stack.grid-stack-6 > .grid-stack-item[data-gs-max-width='6'] {
    max-width: 100%; }

.grid-stack.grid-stack-7 > .grid-stack-item {
  min-width: 14.28571%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-width='1'] {
    width: 14.28571%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-x='1'] {
    left: 14.28571%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-min-width='1'] {
    min-width: 14.28571%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-max-width='1'] {
    max-width: 14.28571%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-width='2'] {
    width: 28.57143%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-x='2'] {
    left: 28.57143%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-min-width='2'] {
    min-width: 28.57143%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-max-width='2'] {
    max-width: 28.57143%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-width='3'] {
    width: 42.85714%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-x='3'] {
    left: 42.85714%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-min-width='3'] {
    min-width: 42.85714%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-max-width='3'] {
    max-width: 42.85714%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-width='4'] {
    width: 57.14286%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-x='4'] {
    left: 57.14286%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-min-width='4'] {
    min-width: 57.14286%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-max-width='4'] {
    max-width: 57.14286%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-width='5'] {
    width: 71.42857%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-x='5'] {
    left: 71.42857%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-min-width='5'] {
    min-width: 71.42857%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-max-width='5'] {
    max-width: 71.42857%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-width='6'] {
    width: 85.71429%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-x='6'] {
    left: 85.71429%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-min-width='6'] {
    min-width: 85.71429%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-max-width='6'] {
    max-width: 85.71429%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-width='7'] {
    width: 100%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-x='7'] {
    left: 100%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-min-width='7'] {
    min-width: 100%; }
  .grid-stack.grid-stack-7 > .grid-stack-item[data-gs-max-width='7'] {
    max-width: 100%; }

.grid-stack.grid-stack-8 > .grid-stack-item {
  min-width: 12.5%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-width='1'] {
    width: 12.5%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-x='1'] {
    left: 12.5%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-min-width='1'] {
    min-width: 12.5%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-max-width='1'] {
    max-width: 12.5%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-width='2'] {
    width: 25%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-x='2'] {
    left: 25%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-min-width='2'] {
    min-width: 25%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-max-width='2'] {
    max-width: 25%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-width='3'] {
    width: 37.5%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-x='3'] {
    left: 37.5%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-min-width='3'] {
    min-width: 37.5%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-max-width='3'] {
    max-width: 37.5%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-width='4'] {
    width: 50%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-x='4'] {
    left: 50%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-min-width='4'] {
    min-width: 50%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-max-width='4'] {
    max-width: 50%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-width='5'] {
    width: 62.5%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-x='5'] {
    left: 62.5%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-min-width='5'] {
    min-width: 62.5%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-max-width='5'] {
    max-width: 62.5%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-width='6'] {
    width: 75%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-x='6'] {
    left: 75%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-min-width='6'] {
    min-width: 75%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-max-width='6'] {
    max-width: 75%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-width='7'] {
    width: 87.5%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-x='7'] {
    left: 87.5%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-min-width='7'] {
    min-width: 87.5%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-max-width='7'] {
    max-width: 87.5%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-width='8'] {
    width: 100%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-x='8'] {
    left: 100%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-min-width='8'] {
    min-width: 100%; }
  .grid-stack.grid-stack-8 > .grid-stack-item[data-gs-max-width='8'] {
    max-width: 100%; }

.grid-stack.grid-stack-9 > .grid-stack-item {
  min-width: 11.11111%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-width='1'] {
    width: 11.11111%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-x='1'] {
    left: 11.11111%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-min-width='1'] {
    min-width: 11.11111%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-max-width='1'] {
    max-width: 11.11111%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-width='2'] {
    width: 22.22222%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-x='2'] {
    left: 22.22222%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-min-width='2'] {
    min-width: 22.22222%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-max-width='2'] {
    max-width: 22.22222%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-width='3'] {
    width: 33.33333%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-x='3'] {
    left: 33.33333%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-min-width='3'] {
    min-width: 33.33333%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-max-width='3'] {
    max-width: 33.33333%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-width='4'] {
    width: 44.44444%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-x='4'] {
    left: 44.44444%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-min-width='4'] {
    min-width: 44.44444%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-max-width='4'] {
    max-width: 44.44444%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-width='5'] {
    width: 55.55556%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-x='5'] {
    left: 55.55556%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-min-width='5'] {
    min-width: 55.55556%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-max-width='5'] {
    max-width: 55.55556%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-width='6'] {
    width: 66.66667%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-x='6'] {
    left: 66.66667%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-min-width='6'] {
    min-width: 66.66667%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-max-width='6'] {
    max-width: 66.66667%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-width='7'] {
    width: 77.77778%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-x='7'] {
    left: 77.77778%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-min-width='7'] {
    min-width: 77.77778%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-max-width='7'] {
    max-width: 77.77778%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-width='8'] {
    width: 88.88889%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-x='8'] {
    left: 88.88889%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-min-width='8'] {
    min-width: 88.88889%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-max-width='8'] {
    max-width: 88.88889%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-width='9'] {
    width: 100%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-x='9'] {
    left: 100%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-min-width='9'] {
    min-width: 100%; }
  .grid-stack.grid-stack-9 > .grid-stack-item[data-gs-max-width='9'] {
    max-width: 100%; }

.grid-stack.grid-stack-10 > .grid-stack-item {
  min-width: 10%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-width='1'] {
    width: 10%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-x='1'] {
    left: 10%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-min-width='1'] {
    min-width: 10%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-max-width='1'] {
    max-width: 10%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-width='2'] {
    width: 20%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-x='2'] {
    left: 20%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-min-width='2'] {
    min-width: 20%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-max-width='2'] {
    max-width: 20%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-width='3'] {
    width: 30%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-x='3'] {
    left: 30%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-min-width='3'] {
    min-width: 30%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-max-width='3'] {
    max-width: 30%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-width='4'] {
    width: 40%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-x='4'] {
    left: 40%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-min-width='4'] {
    min-width: 40%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-max-width='4'] {
    max-width: 40%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-width='5'] {
    width: 50%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-x='5'] {
    left: 50%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-min-width='5'] {
    min-width: 50%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-max-width='5'] {
    max-width: 50%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-width='6'] {
    width: 60%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-x='6'] {
    left: 60%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-min-width='6'] {
    min-width: 60%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-max-width='6'] {
    max-width: 60%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-width='7'] {
    width: 70%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-x='7'] {
    left: 70%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-min-width='7'] {
    min-width: 70%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-max-width='7'] {
    max-width: 70%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-width='8'] {
    width: 80%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-x='8'] {
    left: 80%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-min-width='8'] {
    min-width: 80%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-max-width='8'] {
    max-width: 80%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-width='9'] {
    width: 90%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-x='9'] {
    left: 90%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-min-width='9'] {
    min-width: 90%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-max-width='9'] {
    max-width: 90%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-width='10'] {
    width: 100%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-x='10'] {
    left: 100%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-min-width='10'] {
    min-width: 100%; }
  .grid-stack.grid-stack-10 > .grid-stack-item[data-gs-max-width='10'] {
    max-width: 100%; }

.grid-stack.grid-stack-11 > .grid-stack-item {
  min-width: 9.09091%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-width='1'] {
    width: 9.09091%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-x='1'] {
    left: 9.09091%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-min-width='1'] {
    min-width: 9.09091%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-max-width='1'] {
    max-width: 9.09091%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-width='2'] {
    width: 18.18182%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-x='2'] {
    left: 18.18182%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-min-width='2'] {
    min-width: 18.18182%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-max-width='2'] {
    max-width: 18.18182%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-width='3'] {
    width: 27.27273%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-x='3'] {
    left: 27.27273%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-min-width='3'] {
    min-width: 27.27273%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-max-width='3'] {
    max-width: 27.27273%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-width='4'] {
    width: 36.36364%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-x='4'] {
    left: 36.36364%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-min-width='4'] {
    min-width: 36.36364%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-max-width='4'] {
    max-width: 36.36364%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-width='5'] {
    width: 45.45455%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-x='5'] {
    left: 45.45455%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-min-width='5'] {
    min-width: 45.45455%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-max-width='5'] {
    max-width: 45.45455%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-width='6'] {
    width: 54.54545%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-x='6'] {
    left: 54.54545%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-min-width='6'] {
    min-width: 54.54545%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-max-width='6'] {
    max-width: 54.54545%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-width='7'] {
    width: 63.63636%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-x='7'] {
    left: 63.63636%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-min-width='7'] {
    min-width: 63.63636%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-max-width='7'] {
    max-width: 63.63636%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-width='8'] {
    width: 72.72727%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-x='8'] {
    left: 72.72727%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-min-width='8'] {
    min-width: 72.72727%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-max-width='8'] {
    max-width: 72.72727%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-width='9'] {
    width: 81.81818%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-x='9'] {
    left: 81.81818%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-min-width='9'] {
    min-width: 81.81818%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-max-width='9'] {
    max-width: 81.81818%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-width='10'] {
    width: 90.90909%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-x='10'] {
    left: 90.90909%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-min-width='10'] {
    min-width: 90.90909%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-max-width='10'] {
    max-width: 90.90909%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-width='11'] {
    width: 100%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-x='11'] {
    left: 100%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-min-width='11'] {
    min-width: 100%; }
  .grid-stack.grid-stack-11 > .grid-stack-item[data-gs-max-width='11'] {
    max-width: 100%; }

.grid-stack.grid-stack-12 > .grid-stack-item {
  min-width: 8.33333%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-width='1'] {
    width: 8.33333%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-x='1'] {
    left: 8.33333%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-min-width='1'] {
    min-width: 8.33333%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-max-width='1'] {
    max-width: 8.33333%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-width='2'] {
    width: 16.66667%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-x='2'] {
    left: 16.66667%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-min-width='2'] {
    min-width: 16.66667%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-max-width='2'] {
    max-width: 16.66667%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-width='3'] {
    width: 25%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-x='3'] {
    left: 25%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-min-width='3'] {
    min-width: 25%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-max-width='3'] {
    max-width: 25%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-width='4'] {
    width: 33.33333%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-x='4'] {
    left: 33.33333%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-min-width='4'] {
    min-width: 33.33333%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-max-width='4'] {
    max-width: 33.33333%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-width='5'] {
    width: 41.66667%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-x='5'] {
    left: 41.66667%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-min-width='5'] {
    min-width: 41.66667%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-max-width='5'] {
    max-width: 41.66667%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-width='6'] {
    width: 50%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-x='6'] {
    left: 50%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-min-width='6'] {
    min-width: 50%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-max-width='6'] {
    max-width: 50%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-width='7'] {
    width: 58.33333%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-x='7'] {
    left: 58.33333%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-min-width='7'] {
    min-width: 58.33333%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-max-width='7'] {
    max-width: 58.33333%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-width='8'] {
    width: 66.66667%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-x='8'] {
    left: 66.66667%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-min-width='8'] {
    min-width: 66.66667%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-max-width='8'] {
    max-width: 66.66667%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-width='9'] {
    width: 75%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-x='9'] {
    left: 75%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-min-width='9'] {
    min-width: 75%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-max-width='9'] {
    max-width: 75%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-width='10'] {
    width: 83.33333%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-x='10'] {
    left: 83.33333%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-min-width='10'] {
    min-width: 83.33333%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-max-width='10'] {
    max-width: 83.33333%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-width='11'] {
    width: 91.66667%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-x='11'] {
    left: 91.66667%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-min-width='11'] {
    min-width: 91.66667%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-max-width='11'] {
    max-width: 91.66667%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-width='12'] {
    width: 100%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-x='12'] {
    left: 100%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-min-width='12'] {
    min-width: 100%; }
  .grid-stack.grid-stack-12 > .grid-stack-item[data-gs-max-width='12'] {
    max-width: 100%; }
