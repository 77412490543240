/* ---- Theme Variables ---- */
/* Font */
/* Colors */
/* Transitions */
/* Element radius */
/*---- Race Track ----*/
.racepage--cars .racetrack {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #222; }
  .racepage--cars .racetrack > * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; }

.racepage--cars .racetrack__head {
  height: 150px;
  flex: 0 0 auto;
  position: relative;
  overflow: hidden; }
  .racepage--cars .racetrack__head .btn-back {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 6;
    color: rgba(255, 255, 255, 0.55);
    padding: 12px; }
    .racepage--cars .racetrack__head .btn-back i {
      display: inline-block;
      transform: rotate(180deg);
      position: relative;
      top: -1px; }
    .racepage--cars .racetrack__head .btn-back:hover {
      color: #fff; }

.racepage--cars .racetrack__title {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 10px;
  text-align: center; }
  .racepage--cars .racetrack__title h1 {
    font-size: 1.1em;
    margin: 0;
    color: #fff; }

.racepage--cars .racetrack__logo {
  position: absolute;
  left: 80px;
  top: 50%;
  transform: translateY(-50%); }
  .racepage--cars .racetrack__logo img {
    width: 100px;
    height: auto; }

.racepage--cars .ahead-logo {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2; }
  .racepage--cars .ahead-logo img {
    width: 100px;
    height: auto; }

.racepage--cars .racetrack__flag {
  position: absolute;
  right: -50px;
  bottom: 0;
  transition: all 1.6s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .racepage--cars .racetrack__flag img {
    width: 40px;
    height: auto; }

.racepage--cars .racetrack__bg {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  background-repeat: repeat-x;
  background-size: auto 100%;
  transition: left 5s ease; }

.racepage--cars .racetrack__track {
  flex: 1 1 auto;
  position: relative;
  z-index: 1; }

.racepage--cars .list-racers {
  position: absolute;
  margin: 0;
  z-index: 10;
  left: 72px;
  top: 0;
  height: 100%;
  right: 0;
  display: flex;
  flex-direction: column; }
  .racepage--cars .list-racers > li {
    display: inline-block;
    position: relative;
    flex: 1 1 auto;
    transition: 5s ease;
    left: 0;
    width: 70px;
    transform: translateX(-100%); }
    .racepage--cars .list-racers > li:hover {
      z-index: 10; }
    .racepage--cars .list-racers > li svg {
      width: 100% !important;
      height: auto !important; }
    .racepage--cars .list-racers > li .bike::before {
      content: "";
      display: inline-block;
      position: absolute;
      bottom: 79%;
      right: 71%;
      width: 30px;
      height: 30px;
      background-image: url(../images/winner.svg);
      background-repeat: no-repeat;
      background-size: contain;
      transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transform-origin: center;
      transform: scale(0);
      opacity: 0; }
    .racepage--cars .list-racers > li.winner .bike::before {
      transform: scale(1);
      opacity: 1;
      animation: pulseWin linear 4s;
      animation-iteration-count: infinite;
      transform-origin: 50% 50%;
      transition-delay: .2s; }
    .racepage--cars .list-racers > li.winner .list-racers__name {
      left: 20%;
      top: 26%;
      background-color: #FFD15D;
      color: #222; }
  .racepage--cars .list-racers .bike {
    position: relative; }

@keyframes pulseWin {
  0% {
    transform: scale(1) translateY(0); }
  50% {
    transform: scale(1.05) translateY(6px); }
  100% {
    transform: scale(1) translateY(0); } }

.racepage--cars .list-racers__name {
  margin: 0;
  position: absolute;
  left: 108%;
  top: 22%;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  padding: 0 2px;
  display: inline-block;
  background-color: rgba(145, 149, 151, 0.85);
  transition: 1s ease;
  white-space: nowrap;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis; }

.racepage--cars .list-racers__score {
  position: absolute;
  padding: 8px 10px;
  line-height: 1em;
  left: 50%;
  bottom: 62%;
  transform: translateX(-50%) translateY(10px);
  font-size: 13px;
  font-weight: 700;
  background-color: rgba(145, 149, 151, 0.85);
  color: #fff;
  border-radius: 3px;
  transition: .2s ease-out;
  opacity: 0; }

.racepage--cars .racetrack--labelsback .list-racers__name {
  left: -2%;
  transform: translateX(-100%); }

.racepage--cars .bike:hover .list-racers__score {
  opacity: 1;
  transform: translateX(-50%) translateY(0); }

.racepage--cars .racetrack--more-than-7 .list-racers > li {
  max-height: 7.14285714286%; }
  .racepage--cars .racetrack--more-than-7 .list-racers > li .bike {
    position: absolute;
    bottom: 30%; }

.racepage--cars .racetrack--more-than-14 .track:not(.track--single) {
  display: none; }

.racepage--cars .racetrack--more-than-14 .track--single {
  display: block; }
  .racepage--cars .racetrack--more-than-14 .track--single .track__start {
    left: 63px; }
  .racepage--cars .racetrack--more-than-14 .track--single .track__finish {
    width: 80px; }

.racepage--cars .racetrack--more-than-14 .list-racers > li .bike {
  position: absolute;
  bottom: 25%; }

.racepage--cars .racetrack--self .list-racers > li {
  max-height: 5%; }
  .racepage--cars .racetrack--self .list-racers > li .bike {
    position: absolute;
    bottom: 0; }

.racepage--cars .tracks {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px); }

.racepage--cars .track {
  background: #4b4f50;
  background: -moz-linear-gradient(top, #4b4f50 0%, #383c3d 100%);
  background: -webkit-linear-gradient(top, #4b4f50 0%, #383c3d 100%);
  background: linear-gradient(to bottom, #4b4f50 0%, #383c3d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4b4f50', endColorstr='#383c3d',GradientType=0 );
  position: relative;
  flex: 1 1 auto; }
  .racepage--cars .track + .track {
    box-shadow: inset 0 3px 0 rgba(255, 255, 255, 0.2); }
    .racepage--cars .track + .track .track__finish {
      top: 3px; }

.racepage--cars .track__label {
  position: absolute;
  right: 15px;
  top: 5px;
  color: #fff;
  font-size: 1.8em;
  line-height: 1em;
  opacity: .5;
  letter-spacing: -0.035em;
  transition: 1.5s ease-out;
  margin: 0; }

.racepage--cars .track__start {
  position: absolute;
  left: 50px;
  top: 0;
  height: 100%; }
  .racepage--cars .track__start img {
    height: 100%;
    width: auto; }

.racepage--cars .track__finish {
  position: absolute;
  right: -110px;
  top: 0;
  width: 110px;
  height: 100%;
  background-image: url(../images/track-finish-alt.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: auto 100%;
  transition: all 2.5s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .racepage--cars .track__finish img {
    height: 100%;
    width: auto; }

.racepage--cars .track__line {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 3px;
  transform: translateY(-50%);
  background-position: 0 0;
  background-size: 200% auto;
  background-repeat: repeat-x;
  opacity: .55; }

.racepage--cars .track__top,
.racepage--cars .track__bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  height: 4px;
  background-size: auto 100%; }

.racepage--cars .track__bottom {
  top: auto;
  bottom: 0; }

.racepage--cars .track__logo {
  position: absolute;
  left: 10%;
  right: 10%;
  top: 10%;
  bottom: 10%; }
  .racepage--cars .track__logo img {
    position: absolute;
    max-width: 90%;
    max-height: 90%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: .1; }

.racepage--cars .track--single {
  display: none; }
  .racepage--cars .track--single .track__start {
    width: 12px;
    background-color: rgba(255, 255, 255, 0.35);
    position: absolute;
    left: 60px;
    top: 0;
    height: 100%; }
  .racepage--cars .track--single .track__finish {
    background-image: url(../images/track-finish-single-invert.png);
    background-repeat: repeat-y;
    background-position: top left;
    background-size: 14px auto;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    right: -130px;
    top: 0;
    height: 100%;
    width: 130px; }
  .racepage--cars .track--single .track__line {
    right: 0;
    left: 0;
    background-position: right top;
    background-size: auto 100%; }
  .racepage--cars .track--single .track__label {
    top: 12px; }

.racepage--cars .track-dirt-single {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: auto 100%; }

.racepage--cars .showfinish .racetrack__flag {
  right: 2px;
  transition-delay: .6s; }

.racepage--cars .showfinish .track__finish {
  right: 0 !important; }

.racepage--cars .showfinish .track__label {
  right: 90px; }

.racepage--cars .scoreboard__head {
  background: #5a9fd6;
  background: -moz-linear-gradient(top, #5a9fd6 0%, #4068a0 100%);
  background: -webkit-linear-gradient(top, #5a9fd6 0%, #4068a0 100%);
  background: linear-gradient(to bottom, #5a9fd6 0%, #4068a0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5a9fd6', endColorstr='#4068a0',GradientType=0 ); }

.racepage--cars .list-scores > li:not(.list-scores__head).active {
  transform: rotateX(0) translateY(0) scale(1) skew(-15deg); }
  .racepage--cars .list-scores > li:not(.list-scores__head).active > * {
    transform: skew(15deg); }

.racepage--cars .list-scores > li.list-scores__head {
  background-image: none;
  background-color: #152543; }

.racepage--cars .racepage .tooltip {
  margin-top: 15px; }

@media screen and (min-width: 1400px) {
  .racepage--cars .track__label {
    font-size: 2.5em; } }

@media screen and (max-width: 767px) {
  .racepage--cars .racetrack__logo {
    left: 10px;
    background-color: rgba(255, 255, 255, 0.15);
    padding: 0;
    top: 55%;
    border-radius: 4px; }
    .racepage--cars .racetrack__logo img {
      width: 65px; }
  .racepage--cars .racetrack__title h1 {
    font-size: .9em; }
  .racepage--cars .ahead-logo {
    right: 5px;
    top: 5px; }
    .racepage--cars .ahead-logo img {
      width: 76px; }
  .racepage--cars .list-racers {
    left: 55px; }
    .racepage--cars .list-racers > li {
      width: 55px; }
  .racepage--cars .track__start {
    left: 26px; }
  .racepage--cars .track__finish {
    width: 80px;
    right: -80px; }
  .racepage--cars .track__label {
    font-size: 1.2em;
    top: 8px;
    right: 5px; }
  .racepage--cars .showfinish .track__label {
    right: 75px; }
  .racepage--cars .showfinish .racetrack--more-than-14 .track__label {
    right: 75px; }
  .racepage--cars .racetrack--more-than-14 .track--single .track__start {
    left: 39px;
    width: 8px; }
  .racepage--cars .racetrack--more-than-14 .track--single .track__finish {
    background-size: 6px auto;
    width: 65px; }
  .racepage--cars .list-scores > li.goldalt::before, .racepage--cars .list-scores > li.silveralt::before, .racepage--cars .list-scores > li.bronzealt::before {
    width: 24px;
    height: 24px;
    left: -12px;
    transform: skew(15deg); }
  .racepage--cars .modal-scoreboard .modal-content {
    padding-left: 8px;
    padding-right: 8px; } }

@media screen and (min-width: 768px) and (min-height: 600px) {
  .racepage--cars .racetrack--larger-cars .track__start {
    left: 57px; }
  .racepage--cars .racetrack--larger-cars .track__finish {
    width: 120px;
    right: -120px; }
  .racepage--cars .racetrack--larger-cars .list-racers {
    left: 82px; }
    .racepage--cars .racetrack--larger-cars .list-racers > li {
      width: 80px; }
    .racepage--cars .racetrack--larger-cars .list-racers .bike {
      position: absolute;
      bottom: 38%; }
  .racepage--cars .racetrack--largest-cars .track__start {
    left: 74px; }
  .racepage--cars .racetrack--largest-cars .track__finish {
    width: 145px;
    right: -145px; }
  .racepage--cars .racetrack--largest-cars .list-racers {
    left: 102px; }
    .racepage--cars .racetrack--largest-cars .list-racers > li {
      width: 100px; }
    .racepage--cars .racetrack--largest-cars .list-racers .bike {
      position: absolute;
      bottom: 36%; }
  .racepage--cars .racetrack--largest-cars .list-racers__name {
    font-size: .9em; }
  .racepage--cars .racetrack--ultralarge-cars .track__start {
    left: 95px; }
  .racepage--cars .racetrack--ultralarge-cars .track__finish {
    width: 160px;
    right: -160px; }
  .racepage--cars .racetrack--ultralarge-cars .list-racers {
    left: 126px; }
    .racepage--cars .racetrack--ultralarge-cars .list-racers > li {
      width: 125px; }
    .racepage--cars .racetrack--ultralarge-cars .list-racers .bike {
      position: absolute;
      bottom: 36%; }
  .racepage--cars .racetrack--ultralarge-cars .list-racers__name {
    font-size: .95em; }
  .racepage--cars .racetrack--smaller-cars .track__start {
    left: 42px; }
  .racepage--cars .racetrack--smaller-cars .track__finish {
    width: 105px;
    right: -105px; }
  .racepage--cars .racetrack--smaller-cars .list-racers {
    left: 62px; }
    .racepage--cars .racetrack--smaller-cars .list-racers > li {
      width: 60px; }
  .racepage--cars .racetrack--smallest-cars .track__start {
    left: 33px; }
  .racepage--cars .racetrack--smallest-cars .track__finish {
    width: 90px;
    right: -90px; }
  .racepage--cars .racetrack--smallest-cars .list-racers {
    left: 50px; }
    .racepage--cars .racetrack--smallest-cars .list-racers > li {
      width: 48px; }
  .racepage--cars .racetrack--more-than-14.racetrack--smallest-cars .track--single .track__start {
    width: 8px;
    left: 45px; }
  .racepage--cars .racetrack--more-than-14.racetrack--smallest-cars .track--single .track__finish {
    width: 55px;
    background-size: 8px auto; }
  .racepage--cars .racetrack--more-than-14.racetrack--smaller-cars .track--single .track__start {
    width: 8px;
    left: 60px; }
  .racepage--cars .racetrack--more-than-14.racetrack--smaller-cars .track--single .track__finish {
    width: 65px;
    background-size: 8px auto; }
  .racepage--cars .racetrack--more-than-14.racetrack--larger-cars .track--single .track__start {
    left: 70px; }
  .racepage--cars .racetrack--more-than-14.racetrack--larger-cars .track--single .track__finish {
    width: 90px; }
  .racepage--cars .racetrack--more-than-14.racetrack--largest-cars .track--single .track__start {
    left: 90px; }
  .racepage--cars .racetrack--more-than-14.racetrack--largest-cars .track--single .track__finish {
    width: 115px; }
  .racepage--cars .racetrack--more-than-14.racetrack--ultralarge-cars .track--single .track__start {
    left: 114px; }
  .racepage--cars .racetrack--more-than-14.racetrack--ultralarge-cars .track--single .track__finish {
    width: 136px; }
  .racepage--cars .showfinish .racetrack--larger-cars .track__label {
    right: 120px; }
  .racepage--cars .showfinish .racetrack--largest-cars .track__label {
    right: 130px; }
  .racepage--cars .showfinish .racetrack--ultralarge-cars .track__label {
    right: 160px; } }

@media screen and (max-width: 992px) and (max-height: 450px) {
  .racepage--cars .racetrack__head {
    height: 68px; }
    .racepage--cars .racetrack__head .btn-back {
      left: 60px; }
  .racepage--cars .racetrack__logo img {
    width: 56px; }
  .racepage--cars .list-racers {
    left: 55px; }
    .racepage--cars .list-racers > li {
      width: 55px; }
  .racepage--cars .track + .track {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.8); }
  .racepage--cars .tracks {
    height: calc(100vh - 68px); }
  .racepage--cars .track__start {
    left: 26px; }
  .racepage--cars .track__finish {
    width: 75px;
    right: -75px; }
  .racepage--cars .racetrack--more-than-14 .track--single .track__start {
    left: 39px;
    width: 8px; }
  .racepage--cars .racetrack--more-than-14 .track--single .track__finish {
    background-size: 6px auto;
    width: 62px; }
  .racepage--cars .racepage__ctrl {
    top: 35px;
    right: 5px; }
    .racepage--cars .racepage__ctrl .form .form-group {
      vertical-align: top; }
    .racepage--cars .racepage__ctrl .form .form-control {
      height: 28px !important;
      padding-top: 3px;
      padding-bottom: 3px; }
    .racepage--cars .racepage__ctrl .btn-outline {
      padding-top: 8px;
      padding-bottom: 8px;
      min-height: 28px; }
  .racepage--cars .track__label {
    top: 3px;
    font-size: 1.2em; }
  .racepage--cars .racetrack--more-than-14 .track__label {
    top: 7px; }
  .racepage--cars .showfinish .track__label {
    right: 60px; } }
