/* ---- Theme Variables ---- */
/* Font */
/* Colors */
/* Transitions */
/* Element radius */
/*---- Race Track ----*/
.racepage--bikes .racetrack {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden; }
  .racepage--bikes .racetrack > * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; }

.racepage--bikes .racetrack__head {
  height: 150px;
  flex: 0 0 auto;
  position: relative;
  overflow: hidden; }
  .racepage--bikes .racetrack__head .btn-back {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 6;
    color: rgba(47, 55, 66, 0.4);
    padding: 12px; }
    .racepage--bikes .racetrack__head .btn-back i {
      display: inline-block;
      transform: rotate(180deg);
      position: relative;
      top: -1px; }
    .racepage--bikes .racetrack__head .btn-back:hover {
      color: rgba(47, 55, 66, 0.9); }

.racepage--bikes .racetrack__title {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 10px;
  text-align: center; }
  .racepage--bikes .racetrack__title h1 {
    font-size: 1.1em;
    margin: 0; }

.racepage--bikes .racetrack__logo {
  position: absolute;
  left: 80px;
  top: 50%;
  transform: translateY(-50%); }
  .racepage--bikes .racetrack__logo img {
    width: 100px;
    height: auto; }

.racepage--bikes .ahead-logo {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2; }
  .racepage--bikes .ahead-logo img {
    width: 100px;
    height: auto; }

.racepage--bikes .racetrack__flag {
  position: absolute;
  right: -50px;
  bottom: 0;
  transition: all 1.6s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .racepage--bikes .racetrack__flag img {
    width: 40px;
    height: auto; }

.racepage--bikes .racetrack__bg {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  background-repeat: repeat-x;
  background-size: auto 100%;
  transition: left 5s ease; }

.racepage--bikes .racetrack__track {
  flex: 1 1 auto;
  position: relative;
  z-index: 1; }

.racepage--bikes .racepage__ctrl {
  position: absolute;
  left: 20px;
  top: 50px;
  z-index: 5;
  display: flex; }
  .racepage--bikes .racepage__ctrl .btn-outline {
    color: #2F3742;
    background-color: rgba(255, 255, 255, 0.85);
    padding: 10px 18px;
    font-size: .8em;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25); }
    .racepage--bikes .racepage__ctrl .btn-outline:hover {
      background-color: #fff;
      color: #09A709; }
  .racepage--bikes .racepage__ctrl .form .form-group {
    display: inline-block; }
  .racepage--bikes .racepage__ctrl .form .form-control {
    font-size: .8em;
    height: 33px !important;
    border: none;
    background-color: rgba(255, 255, 255, 0.85);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25); }
    .racepage--bikes .racepage__ctrl .form .form-control:hover {
      background-color: #fff; }

.racepage--bikes .racepage--single .racetrack__title h1 {
  display: inline-block;
  position: relative; }
  .racepage--bikes .racepage--single .racetrack__title h1 span {
    display: block;
    background: #FFF;
    padding: 7px 14px;
    text-align: center;
    text-decoration: none;
    z-index: 1;
    box-shadow: 0 2px 14px rgba(228, 107, 27, 0.15); }
  .racepage--bikes .racepage--single .racetrack__title h1::before, .racepage--bikes .racepage--single .racetrack__title h1::after {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 30px;
    height: 100%;
    background: rgba(9, 167, 9, 0.5);
    transform: skewY(8deg);
    z-index: -3;
    border-radius: 1px; }
  .racepage--bikes .racepage--single .racetrack__title h1::after {
    left: auto;
    top: -2px;
    right: 0;
    background: rgba(228, 107, 27, 0.5); }

.racepage--bikes .racetrack--self .list-racers > li:nth-child(5n + 1) .bike {
  bottom: -45px; }

.racepage--bikes .racetrack--self .list-racers > li:nth-child(5n) .bike {
  bottom: -45px; }

.racepage--bikes .racetrack--self .list-racers > li:nth-child(5n + 2) .bike {
  bottom: -33px; }

.racepage--bikes .racetrack--self .list-racers > li:nth-child(5n + 3) .bike {
  bottom: -24px; }

.racepage--bikes .racetrack--self .list-racers > li:nth-child(5n + 4) .bike {
  bottom: -14px; }

.racepage--bikes .racetrack--self .list-racers > li:nth-child(5n + 5) .bike {
  bottom: -2px; }

.racepage--bikes .list-racers {
  position: absolute;
  margin: 0;
  z-index: 10;
  left: 72px;
  top: 0;
  height: 100%;
  right: 0;
  display: flex;
  flex-direction: column; }
  .racepage--bikes .list-racers > li {
    display: inline-block;
    position: relative;
    flex: 1 1 auto;
    transition: 5s ease;
    left: 0;
    width: 70px;
    transform: translateX(-100%); }
    .racepage--bikes .list-racers > li svg {
      width: 100% !important;
      height: auto !important; }
    .racepage--bikes .list-racers > li .bike::before {
      content: "";
      display: inline-block;
      position: absolute;
      bottom: 79%;
      right: 71%;
      width: 30px;
      height: 30px;
      background-image: url(../images/winner.svg);
      background-repeat: no-repeat;
      background-size: contain;
      transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transform-origin: center;
      transform: scale(0);
      opacity: 0; }
    .racepage--bikes .list-racers > li.winner .bike::before {
      transform: scale(1);
      opacity: 1;
      animation: pulseWin linear 4s;
      animation-iteration-count: infinite;
      transform-origin: 50% 50%;
      transition-delay: .2s; }
    .racepage--bikes .list-racers > li.winner .list-racers__name {
      left: 20%;
      top: 26%;
      background-color: #FFD15D;
      color: #222; }
  .racepage--bikes .list-racers .bike {
    position: relative; }

@keyframes pulseWin {
  0% {
    transform: scale(1) translateY(0); }
  50% {
    transform: scale(1.05) translateY(6px); }
  100% {
    transform: scale(1) translateY(0); } }

.racepage--bikes .list-racers__name {
  margin: 0;
  position: absolute;
  left: 100%;
  top: 22%;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  padding: 0 2px;
  display: inline-block;
  background-color: rgba(145, 149, 151, 0.85);
  transition: 1s ease;
  white-space: nowrap;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis; }

.racepage--bikes .list-racers__score {
  position: absolute;
  padding: 8px 10px;
  line-height: 1em;
  left: 50%;
  bottom: 62%;
  transform: translateX(-50%) translateY(10px);
  font-size: 13px;
  font-weight: 700;
  background-color: rgba(145, 149, 151, 0.85);
  color: #fff;
  border-radius: 3px;
  transition: .2s ease-out;
  opacity: 0; }

.racepage--bikes .racetrack--labelsback .list-racers__name {
  left: 15%;
  transform: translateX(-100%); }

.racepage--bikes .bike:hover .list-racers__score {
  opacity: 1;
  transform: translateX(-50%) translateY(0); }

.racepage--bikes .racetrack--more-than-7 .list-racers > li {
  max-height: 7.14285714286%; }
  .racepage--bikes .racetrack--more-than-7 .list-racers > li .bike {
    position: absolute;
    bottom: 30%; }

.racepage--bikes .racetrack--more-than-14 .track:not(.track--single) {
  display: none; }

.racepage--bikes .racetrack--more-than-14 .track--single {
  display: block; }
  .racepage--bikes .racetrack--more-than-14 .track--single .track__start {
    left: 63px; }
  .racepage--bikes .racetrack--more-than-14 .track--single .track__finish {
    width: 80px; }

.racepage--bikes .racetrack--more-than-14 .list-racers > li .bike {
  position: absolute;
  bottom: 25%; }

.racepage--bikes .racetrack--self .list-racers > li {
  max-height: 5%; }
  .racepage--bikes .racetrack--self .list-racers > li .bike {
    position: absolute;
    bottom: 0; }

.racepage--bikes .tracks {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px); }

.racepage--bikes .track {
  background: #a5a9ab;
  background: -moz-linear-gradient(top, #a5a9ab 0%, #8d9295 100%);
  background: -webkit-linear-gradient(top, #a5a9ab 0%, #8d9295 100%);
  background: linear-gradient(to bottom, #a5a9ab 0%, #8d9295 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a5a9ab', endColorstr='#8d9295',GradientType=0 );
  position: relative;
  flex: 1 1 auto; }
  .racepage--bikes .track + .track {
    box-shadow: inset 0 3px 0 rgba(255, 255, 255, 0.8); }

.racepage--bikes .track__label {
  position: absolute;
  right: 15px;
  top: 5px;
  color: #fff;
  font-size: 1.8em;
  line-height: 1em;
  opacity: .5;
  letter-spacing: -0.035em;
  transition: 1.5s ease-out;
  margin: 0; }

.racepage--bikes .track__start {
  position: absolute;
  left: 50px;
  top: 0;
  height: 100%; }
  .racepage--bikes .track__start img {
    height: 100%;
    width: auto; }

.racepage--bikes .track__finish {
  position: absolute;
  right: -100px;
  top: 0;
  width: 100px;
  height: 100%;
  background-image: url(../images/track-finish.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: auto 100%;
  transition: all 2.5s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .racepage--bikes .track__finish img {
    height: 100%;
    width: auto; }

.racepage--bikes .track__line {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 3px;
  transform: translateY(-50%);
  background-position: 0 0;
  background-size: 200% auto;
  background-repeat: repeat-x; }

.racepage--bikes .track__top,
.racepage--bikes .track__bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  height: 4px;
  background-size: auto 100%; }

.racepage--bikes .track__bottom {
  top: auto;
  bottom: 0; }

.racepage--bikes .track__logo {
  position: absolute;
  left: 10%;
  right: 10%;
  top: 10%;
  bottom: 10%; }
  .racepage--bikes .track__logo img {
    position: absolute;
    max-width: 90%;
    max-height: 90%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: .1; }

.racepage--bikes .track--single {
  display: none; }
  .racepage--bikes .track--single .track__start {
    width: 12px;
    background-color: rgba(255, 255, 255, 0.35);
    position: absolute;
    left: 60px;
    top: 0;
    height: 100%; }
  .racepage--bikes .track--single .track__finish {
    background-image: url(../images/track-finish-single.png);
    background-repeat: repeat-y;
    background-position: top left;
    background-size: 14px auto;
    background-color: rgba(255, 255, 255, 0.25);
    position: absolute;
    right: -130px;
    top: 0;
    height: 100%;
    width: 130px; }
  .racepage--bikes .track--single .track__line {
    right: 0;
    left: 0;
    background-position: right top;
    background-size: auto 100%; }

.racepage--bikes .track-dirt-single {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 2800px;
  background-size: auto 100%; }

.racepage--bikes .dark-theme .racetrack {
  background-color: #222; }

.racepage--bikes .dark-theme .btn-back {
  color: rgba(255, 255, 255, 0.55); }
  .racepage--bikes .dark-theme .btn-back:hover {
    color: #fff; }

.racepage--bikes .dark-theme .racetrack__title h1 {
  color: #fff; }

.racepage--bikes .dark-theme .track {
  background: #4b4f50;
  background: -moz-linear-gradient(top, #4b4f50 0%, #383c3d 100%);
  background: -webkit-linear-gradient(top, #4b4f50 0%, #383c3d 100%);
  background: linear-gradient(to bottom, #4b4f50 0%, #383c3d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4b4f50', endColorstr='#383c3d',GradientType=0 );
  position: relative;
  flex: 1 1 auto; }
  .racepage--bikes .dark-theme .track + .track {
    box-shadow: inset 0 3px 0 rgba(255, 255, 255, 0.2); }

.racepage--bikes .dark-theme .track__finish {
  background-image: url(../images/track-finish-dark.png); }

.racepage--bikes .dark-theme .track--single .track__finish {
  background-color: rgba(0, 0, 0, 0.25);
  background-image: url(../images/track-finish-single.png); }

.racepage--bikes .dark-theme .scoreboard__head {
  background: #5a9fd6;
  background: -moz-linear-gradient(top, #5a9fd6 0%, #4068a0 100%);
  background: -webkit-linear-gradient(top, #5a9fd6 0%, #4068a0 100%);
  background: linear-gradient(to bottom, #5a9fd6 0%, #4068a0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5a9fd6', endColorstr='#4068a0',GradientType=0 ); }

.racepage--bikes .showfinish .racetrack__flag {
  right: 2px;
  transition-delay: .6s; }

.racepage--bikes .showfinish .track__finish {
  right: 0 !important; }

.racepage--bikes .showfinish .track__label {
  right: 65px; }

@media screen and (min-width: 1400px) {
  .racepage--bikes .track__label {
    font-size: 2.5em; } }

@media screen and (min-width: 993px) {
  .racepage--bikes .racepage--single .racetrack__title h1 {
    font-size: 1.3em; } }

@media screen and (max-width: 767px) {
  .racepage--bikes .racetrack__logo {
    left: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 6px;
    top: 55%;
    border-radius: 4px; }
    .racepage--bikes .racetrack__logo img {
      width: 65px; }
  .racepage--bikes .racetrack__title h1 {
    font-size: .9em; }
  .racepage--bikes .racepage--single .racetrack__title {
    top: 4px; }
  .racepage--bikes .racepage__ctrl {
    top: 40px;
    left: 10px; }
  .racepage--bikes .ahead-logo {
    right: 5px;
    top: 5px; }
    .racepage--bikes .ahead-logo img {
      width: 76px; }
  .racepage--bikes .list-racers {
    left: 44px; }
    .racepage--bikes .list-racers > li {
      width: 42px; }
  .racepage--bikes .track__start {
    left: 26px; }
  .racepage--bikes .track__finish {
    width: 58px;
    right: -58px; }
  .racepage--bikes .track__label {
    font-size: 1.5em;
    top: 8px;
    right: 5px; }
  .racepage--bikes .showfinish .track__label {
    right: 35px; }
  .racepage--bikes .showfinish .racetrack--more-than-14 .track__label {
    right: 55px; }
  .racepage--bikes .racetrack--more-than-14 .track--single .track__start {
    left: 39px;
    width: 8px; }
  .racepage--bikes .racetrack--more-than-14 .track--single .track__finish {
    background-size: 6px auto;
    width: 45px; }
  .racepage--bikes .list-scores > li.goldalt::before, .racepage--bikes .list-scores > li.silveralt::before, .racepage--bikes .list-scores > li.bronzealt::before {
    width: 24px;
    height: 24px;
    left: -12px; }
  .racepage--bikes .modal-scoreboard .modal-content {
    padding-left: 8px;
    padding-right: 8px; } }

@media screen and (min-width: 768px) and (min-height: 600px) {
  .racepage--bikes .racetrack--larger-bikes .track__start {
    left: 57px; }
  .racepage--bikes .racetrack--larger-bikes .track__finish {
    width: 110px;
    right: -110px; }
  .racepage--bikes .racetrack--larger-bikes .list-racers {
    left: 82px; }
    .racepage--bikes .racetrack--larger-bikes .list-racers > li {
      width: 80px; }
    .racepage--bikes .racetrack--larger-bikes .list-racers .bike {
      position: absolute;
      bottom: 38%; }
  .racepage--bikes .racetrack--largest-bikes .track__start {
    left: 74px; }
  .racepage--bikes .racetrack--largest-bikes .track__finish {
    width: 120px;
    right: -120px; }
  .racepage--bikes .racetrack--largest-bikes .list-racers {
    left: 102px; }
    .racepage--bikes .racetrack--largest-bikes .list-racers > li {
      width: 100px; }
    .racepage--bikes .racetrack--largest-bikes .list-racers .bike {
      position: absolute;
      bottom: 36%; }
  .racepage--bikes .racetrack--largest-bikes .list-racers__name {
    font-size: .9em; }
  .racepage--bikes .racetrack--ultralarge-bikes .track__start {
    left: 95px; }
  .racepage--bikes .racetrack--ultralarge-bikes .track__finish {
    width: 150px;
    right: -150px; }
  .racepage--bikes .racetrack--ultralarge-bikes .list-racers {
    left: 126px; }
    .racepage--bikes .racetrack--ultralarge-bikes .list-racers > li {
      width: 125px; }
    .racepage--bikes .racetrack--ultralarge-bikes .list-racers .bike {
      position: absolute;
      bottom: 36%; }
  .racepage--bikes .racetrack--ultralarge-bikes .list-racers__name {
    font-size: .95em; }
  .racepage--bikes .racetrack--smaller-bikes .track__start {
    left: 42px; }
  .racepage--bikes .racetrack--smaller-bikes .track__finish {
    width: 90px;
    right: -90px; }
  .racepage--bikes .racetrack--smaller-bikes .list-racers {
    left: 62px; }
    .racepage--bikes .racetrack--smaller-bikes .list-racers > li {
      width: 60px; }
  .racepage--bikes .racetrack--smallest-bikes .track__start {
    left: 33px; }
  .racepage--bikes .racetrack--smallest-bikes .track__finish {
    width: 80px;
    right: -80px; }
  .racepage--bikes .racetrack--smallest-bikes .list-racers {
    left: 50px; }
    .racepage--bikes .racetrack--smallest-bikes .list-racers > li {
      width: 48px; }
  .racepage--bikes .racetrack--more-than-14.racetrack--smallest-bikes .track--single .track__start {
    width: 8px;
    left: 45px; }
  .racepage--bikes .racetrack--more-than-14.racetrack--smallest-bikes .track--single .track__finish {
    width: 55px;
    background-size: 8px auto; }
  .racepage--bikes .racetrack--more-than-14.racetrack--smaller-bikes .track--single .track__start {
    width: 8px;
    left: 60px; }
  .racepage--bikes .racetrack--more-than-14.racetrack--smaller-bikes .track--single .track__finish {
    width: 65px;
    background-size: 8px auto; }
  .racepage--bikes .racetrack--more-than-14.racetrack--larger-bikes .track--single .track__start {
    left: 70px; }
  .racepage--bikes .racetrack--more-than-14.racetrack--larger-bikes .track--single .track__finish {
    width: 90px; }
  .racepage--bikes .racetrack--more-than-14.racetrack--largest-bikes .track--single .track__start {
    left: 90px; }
  .racepage--bikes .racetrack--more-than-14.racetrack--largest-bikes .track--single .track__finish {
    width: 100px; }
  .racepage--bikes .racetrack--more-than-14.racetrack--ultralarge-bikes .track--single .track__start {
    left: 114px; }
  .racepage--bikes .racetrack--more-than-14.racetrack--ultralarge-bikes .track--single .track__finish {
    width: 126px; } }

@media screen and (max-width: 992px) and (max-height: 450px) {
  .racepage--bikes .racetrack__head {
    height: 68px; }
  .racepage--bikes .racetrack__logo img {
    width: 56px; }
  .racepage--bikes .list-racers {
    left: 44px; }
    .racepage--bikes .list-racers > li {
      width: 42px; }
  .racepage--bikes .track + .track {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.8); }
  .racepage--bikes .tracks {
    height: calc(100vh - 68px); }
  .racepage--bikes .track__start {
    left: 26px; }
  .racepage--bikes .track__finish {
    width: 58px;
    right: -58px; }
  .racepage--bikes .racetrack--more-than-14 .track--single .track__start {
    left: 39px;
    width: 8px; }
  .racepage--bikes .racetrack--more-than-14 .track--single .track__finish {
    background-size: 6px auto;
    width: 45px; }
  .racepage--bikes .racepage__ctrl {
    top: 35px;
    left: 10px; }
    .racepage--bikes .racepage__ctrl .form .form-group {
      vertical-align: top; }
    .racepage--bikes .racepage__ctrl .form .form-control {
      height: 28px !important;
      padding-top: 3px;
      padding-bottom: 3px; }
    .racepage--bikes .racepage__ctrl .btn-outline {
      padding-top: 8px;
      padding-bottom: 8px;
      min-height: 28px; }
  .racepage--bikes .track__label {
    top: 3px; }
  .racepage--bikes .racetrack--more-than-14 .track__label {
    top: 7px; }
  .racepage--bikes .showfinish .track__label {
    right: 40px; } }
