/* ---- Theme Variables ---- */
/* Font */
/* Colors */
/* Transitions */
/* Element radius */
/*---- General - Theme Styles ----*/
html {
  font-size: 16px; }

html, body {
  min-height: 100%;
  height: 100%; }

body {
  font-family: Open Sans, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #2F3742; }

p {
  line-height: 1.3em; }

h1, h2, h3, h4, h5, h6 {
  font-family: Open Sans, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 700; }

table {
  width: 100%; }

a {
  color: #09A709; }

a, .btn, button {
  transition: all 0.2s linear; }
  a:hover, .btn:hover, button:hover {
    text-decoration: none;
    cursor: pointer; }
  a:focus, .btn:focus, button:focus {
    outline: none;
    box-shadow: none;
    text-decoration: none; }

#root {
  height: 100%; }
  #root > .App {
    height: 100%; }

/* Helpers */
.overlay {
  background-color: rgba(45, 54, 68, 0.75);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 40;
  transition: .3s all; }

.notopmargin {
  margin-top: 0 !important; }

.nobottommargin {
  margin-bottom: 0 !important; }

.notoppadding {
  padding-top: 0 !important; }

.nopadding {
  padding: 0 !important; }

.noborder {
  border: none !important; }

.notopborder {
  border-top: none !important; }

.hidden-block {
  display: none; }

.delimited {
  margin-bottom: 20px; }

.distancer {
  height: 25px; }
  .distancer.distancer--short {
    height: 12px; }
  .distancer.distancer--long {
    height: 60px; }

.distancerh {
  padding: 0 8px; }

.grey {
  color: rgba(47, 55, 66, 0.85); }

.greybg {
  background-color: #ECEDEF; }

.intro {
  font-size: .9em;
  color: rgba(47, 55, 66, 0.85);
  line-height: 1.4em; }

.badge {
  font-weight: 400;
  border-radius: 3px;
  padding: 4px 8px; }
  .badge.badge-success {
    background-color: rgba(0, 200, 83, 0.12);
    color: #00C853; }
  .badge.badge-danger {
    background-color: rgba(242, 71, 56, 0.12);
    color: #F24738; }

.alert {
  border: none;
  position: relative; }
  .alert .alert-danger {
    background-color: rgba(242, 71, 56, 0.15); }
  .alert .close {
    position: absolute;
    right: 0;
    top: 0;
    display: inline-block;
    padding: 6px 8px;
    line-height: 1em;
    text-shadow: none;
    opacity: .5;
    transition: .2s all; }
    .alert .close:hover {
      opacity: .8; }

.picto-confirmation {
  width: 70px !important;
  height: auto !important; }

.avatar {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ecedef; }
  .avatar img {
    width: 100%;
    height: auto;
    vertical-align: top; }

/* Dropdown Menu */
.dropdown-menu {
  padding: 0;
  margin: 0;
  border: 1px solid #d9dbdf;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.125);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.125);
  font-size: .92rem; }
  .dropdown-menu .dropdown-item {
    padding: 10px 28px;
    line-height: 1.1em; }
    .dropdown-menu .dropdown-item:active {
      background-color: #dee0e3;
      color: #000; }

.open > .dropdown-menu:not(.animated) {
  display: block; }

.dropdown-menu--animated {
  display: block;
  visibility: hidden;
  opacity: 0;
  transform-origin: top center;
  transform: scale(0.94) translateY(20px) !important; }

.show > .dropdown-menu--animated {
  transition: .2s ease-out;
  visibility: visible;
  opacity: 1;
  transform: scale(1) translateY(0) !important; }

.dropdown-menu--withicons .dropdown-item {
  padding-right: 60px;
  position: relative; }
  .dropdown-menu--withicons .dropdown-item i {
    position: absolute;
    right: 26px;
    top: 50%;
    transform: translateY(-50%);
    opacity: .35; }

/* Modal */
.modal .close {
  position: absolute;
  right: 0;
  top: 0;
  font-size: .85em;
  margin: 0;
  transition: .2s all;
  outline: none !important; }
  .modal .close i {
    transition: .2s all;
    display: inline-block; }
  .modal .close:hover i {
    transform: scale(1.2); }

.modal.fade .modal-dialog {
  transition: transform 300ms cubic-bezier(0.19, 1, 0.22, 1);
  transform: translate(0, 12%) scale(0.8); }

.modal.fade.show .modal-dialog {
  transform: translate(0, 0) scale(1); }

.modal-lg .modal__intro {
  max-width: 400px; }

.modal__intro {
  text-align: center;
  font-size: .9em;
  margin-bottom: 15px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.45em; }

.modal__cta {
  text-align: center;
  padding-top: 18px; }
  .modal__cta .btn-text {
    font-size: .85em; }

.modal-content {
  border: none;
  border-radius: 3px;
  position: relative; }

.modal-header {
  justify-content: center;
  border-bottom: none;
  position: relative; }

.modal-title {
  text-align: center;
  font-size: .9em;
  flex: 1 1 auto; }

.modal__inner {
  max-width: 240px;
  margin: 0 auto; }

.modal__inner--wide {
  max-width: 320px; }

.modal__inner--wider {
  max-width: 380px; }

.modal__inner--widest {
  max-width: 500px; }

.modal__inner--overrides {
  max-width: 540px; }

.modal-footer {
  border-top: none; }

.modal-footer--center {
  justify-content: center; }

.modal--overrides .modal__overrides {
  max-height: 400px;
  overflow-y: auto; }

.scrollbar::-webkit-scrollbar {
  width: 14px;
  height: 14px; }

.scrollbar::-webkit-scrollbar-track {
  background-color: #fff; }

.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(47, 55, 66, 0.3);
  border-radius: 12px;
  border: 3px solid #fff; }

/* Buttons */
.btn {
  transition: all 0.2s linear;
  text-decoration: none;
  border: none;
  border-radius: 3px;
  padding: 12px 25px;
  font-size: 14px;
  line-height: 1em;
  font-weight: 700;
  color: #fff; }
  .btn:hover {
    text-decoration: none;
    border: none; }
  .btn:focus {
    outline: none !important; }

.btn--default {
  background-color: rgba(47, 55, 66, 0.35);
  color: #fff; }
  .btn--default:hover {
    background-color: rgba(47, 55, 66, 0.3);
    color: #fff; }

.btn--primary,
.btn--primary:focus {
  background-color: #09A709;
  color: #fff; }
  .btn--primary:hover,
  .btn--primary:focus:hover {
    background-color: #0aba0a;
    color: #fff; }

.btn--secondary,
.btn--secondary:focus {
  background-color: rgba(47, 55, 66, 0.45);
  color: #fff; }
  .btn--secondary:hover,
  .btn--secondary:focus:hover {
    background-color: rgba(47, 55, 66, 0.65);
    color: #fff; }

.btn--outline,
.btn--outline:focus {
  background-color: transparent;
  color: rgba(47, 55, 66, 0.4);
  box-shadow: inset 0 0 0 1px rgba(47, 55, 66, 0.15); }
  .btn--outline:hover,
  .btn--outline:focus:hover {
    background-color: transparent;
    color: rgba(47, 55, 66, 0.85);
    box-shadow: inset 0 0 0 1px rgba(47, 55, 66, 0.45); }

.btn--red {
  background-color: #F24738;
  color: #fff; }
  .btn--red:hover {
    background-color: #f45d50;
    color: #fff; }

.btn--wide {
  min-width: 300px; }

.btn--halfwide {
  min-width: 120px; }

.btn-text {
  color: #00C853 !important;
  border-bottom: 1px solid rgba(9, 167, 9, 0.35); }
  .btn-text:hover {
    color: #2F3742 !important;
    border-bottom-color: transparent; }

.btn-close {
  display: inline-block;
  padding: 12px 14px;
  line-height: 1em;
  color: rgba(47, 55, 66, 0.35); }
  .btn-close:hover {
    color: rgba(47, 55, 66, 0.85); }

.btn-action {
  color: rgba(47, 55, 66, 0.3);
  line-height: 1em;
  padding: 7px 9px;
  border-radius: 3px; }
  .btn-action:hover {
    background-color: rgba(47, 55, 66, 0.55);
    color: #fff; }

.show .btn-action,
.show .btn-action:hover {
  background-color: rgba(47, 55, 66, 0.55) !important;
  color: #fff !important; }

.btn-helper {
  color: rgba(47, 55, 66, 0.45);
  display: inline-block;
  position: relative;
  font-size: 13px;
  padding: 0 2px; }
  .btn-helper:hover {
    color: rgba(47, 55, 66, 0.9); }
  .btn-helper .icon-help {
    position: relative;
    top: 1px; }

.btn--withicon i {
  margin-right: 6px;
  position: relative;
  font-size: .9em;
  top: 1px; }

.btn--withlasticon i {
  margin-left: 8px;
  position: relative;
  max-height: 33px;
  top: 1px;
  margin-top: -2px;
  margin-bottom: -2px;
  transition: .2s ease-out;
  display: inline-block; }

.btn--withlasticon:hover .aheadicon-arrow,
.btn--withlasticon:hover .aheadicon-arrow-alt {
  transform: translateX(5px); }

/*---- Login & Signup ----*/
.loginsignup {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  padding: 35px 25px;
  font-size: .9em; }

.loginsetup__logo {
  text-align: center;
  padding-bottom: 35px; }
  .loginsetup__logo img {
    max-width: 160px;
    height: auto; }

.loginsignup__head {
  text-align: center;
  padding-bottom: 12px; }
  .loginsignup__head h5 {
    font-size: 1.05em;
    margin-bottom: .8em; }
  .loginsignup__head .intro {
    font-size: .95em; }
  .loginsignup__head .picto-confirmation {
    margin-bottom: 1em; }

.greybg .loginsignup {
  background-color: #fff;
  border-radius: 6px;
  max-width: 460px;
  padding: 35px 25px;
  box-shadow: 0 8px 25px rgba(47, 55, 66, 0.08); }
  .greybg .loginsignup .form {
    max-width: 260px;
    margin: 0 auto; }

/*---- Header and navbar ----*/
.mobilebar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 8px 12px;
  box-shadow: 0 2px 16px rgba(47, 55, 66, 0.05);
  z-index: 2;
  text-align: center;
  height: 50px;
  display: none; }

.mobilebar__branding img {
  width: 100px;
  height: auto; }

.mobilebar__menu {
  position: absolute;
  left: 0;
  top: 0; }

.btn-mobilemenu {
  background-color: transparent;
  border: none;
  display: inline-flex;
  align-items: center;
  padding: 15px 10px;
  color: #09A709; }
  .btn-mobilemenu:hover {
    color: #2F3742; }
    .btn-mobilemenu:hover .icon-bar {
      background-color: #2F3742; }
      .btn-mobilemenu:hover .icon-bar:nth-child(2) {
        width: 26px; }
  .btn-mobilemenu .title {
    font-size: .8em;
    font-weight: 700;
    padding-left: 8px;
    pointer-events: none; }
  .btn-mobilemenu .bars {
    pointer-events: none; }
  .btn-mobilemenu .icon-bar {
    display: block;
    width: 26px;
    height: 2px;
    background-color: #09A709;
    transition: .2s ease-out;
    pointer-events: none; }
    .btn-mobilemenu .icon-bar + .icon-bar {
      margin-top: 4px; }
    .btn-mobilemenu .icon-bar:nth-child(2) {
      width: 22px; }

/*---- Main Content ----*/
.content--padded {
  padding: 15px; }

.content--account {
  position: relative;
  display: flex;
  height: 100%; }
  .content--account .btn-close {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2; }
  .content--account .main {
    background-color: #fff;
    border-left: 1px solid #ECEDEF; }
  .content--account .loginsignup {
    box-shadow: none;
    border-radius: 0; }

.upload-avatar {
  position: relative;
  text-align: center; }
  .upload-avatar:hover .avatar {
    border-color: #09A709; }
  .upload-avatar:hover p, .upload-avatar:hover i {
    color: #09A709; }
  .upload-avatar p {
    transition: .2s all;
    font-size: .9em; }
  .upload-avatar i {
    color: rgba(9, 167, 9, 0.5);
    transition: .2s all; }
  .upload-avatar .avatar {
    width: 100px;
    height: 100px;
    border: 2px dotted rgba(9, 167, 9, 0.5);
    transition: .2s all;
    line-height: 100px;
    font-size: 2em; }
  .upload-avatar input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer; }

.upload-avatar--uploaded .avatar {
  border: none;
  box-shadow: 0 0 0 1px rgba(236, 237, 239, 0.5); }

.upload-avatar--full .avatar {
  border-radius: 5px;
  width: 100%;
  height: 160px; }

.upload-avatar--full i {
  position: absolute;
  left: 50%;
  top: 44%;
  transform: translate(-50%, -50%);
  line-height: 1em; }

/*---- Dashboard ----*/
.content--main {
  display: flex;
  height: 100%;
  overflow: hidden; }

.side {
  width: 230px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2; }

.side__branding {
  padding: 18px 20px;
  flex: 0 0 auto;
  text-align: center; }
  .side__branding img {
    width: 140px;
    height: auto; }

.side__user {
  flex: 0 0 auto;
  padding: 35px 20px; }
  .side__user .dropdown-menu {
    left: 2px !important;
    right: 2px !important;
    top: auto !important; }
  .side__user .dropdown-item {
    padding: 13px 25px; }

.side__links {
  padding: 0 20px;
  flex: 1 1 auto;
  overflow-y: auto; }
  .side__links .list-links {
    margin-right: -20px; }

.btn-closeside {
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  padding: 10px 12px;
  color: rgba(47, 55, 66, 0.35);
  z-index: 2; }
  .btn-closeside:hover {
    color: rgba(47, 55, 66, 0.86); }

.btn-user {
  display: flex;
  align-items: center;
  color: #2F3742;
  position: relative; }
  .btn-user .aheadicon-caret {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: .7em;
    opacity: .5; }
  .btn-user:hover {
    color: #2F3742; }
    .btn-user:hover .avatar {
      box-shadow: 0 0 0 3px #ECEDEF; }
  .btn-user .avatar {
    flex: 0 0 auto;
    margin-left: 15px;
    margin-right: 14px;
    transition: .2s all;
    box-shadow: 0 0 0 1px rgba(47, 55, 66, 0.05); }
  .btn-user p {
    margin: 0;
    line-height: 1.1em;
    font-size: .9em;
    font-weight: 700; }
  .btn-user .badge {
    background-color: #ECEDEF;
    color: rgba(47, 55, 66, 0.6);
    font-size: 12px;
    padding: 2px 6px;
    font-weight: 400; }

.list-links a {
  display: block;
  font-size: .9em;
  font-weight: 700;
  color: rgba(120, 125, 132, 0.8);
  padding: 14px 20px;
  padding-left: 70px;
  position: relative; }
  .list-links a:hover {
    color: #2F3742; }
  .list-links a.active {
    background-color: #ECEDEF;
    color: #2F3742;
    box-shadow: inset 3px 0 0 #09A709; }
    .list-links a.active i {
      color: #09A709; }
  .list-links a i {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.4em; }

.main {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  position: relative;
  z-index: 1; }

.main__header {
  background-color: #fff;
  height: 80px;
  padding: 10px 35px;
  box-shadow: 0 2px 16px rgba(47, 55, 66, 0.05);
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  border-left: 1px solid #ECEDEF;
  position: relative;
  z-index: 20; }
  .main__header > * {
    flex: 1 1 auto; }

.main__stats p {
  margin: 0;
  font-size: .85em; }

.main__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.btn-new {
  color: #fff !important; }
  .btn-new i {
    display: inline-block;
    transition: .2s ease-out;
    font-size: .8em;
    position: relative;
    top: -1px;
    pointer-events: none; }
  .btn-new.open i {
    transform: rotate(180deg); }

.btn-group--view {
  margin-right: 20px; }
  .btn-group--view .btn {
    color: rgba(47, 55, 66, 0.35);
    padding: 10px 12px; }
    .btn-group--view .btn i {
      font-size: 1.3em; }
    .btn-group--view .btn:hover {
      color: rgba(47, 55, 66, 0.75); }
    .btn-group--view .btn.active {
      color: #2F3742;
      cursor: default; }

.main__body {
  padding: 30px 35px;
  overflow-y: auto; }

.btn-dashgroup {
  display: flex;
  background-color: #fff;
  border-left: 4px solid #09A709;
  padding: 16px 20px;
  padding-left: 60px;
  box-shadow: 0 2px 16px rgba(47, 55, 66, 0.05);
  position: relative;
  margin-top: 4px;
  color: #09A709 !important; }
  .btn-dashgroup::before {
    content: "\e908";
    font-family: "aheadicon";
    position: absolute;
    left: 22px;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(47, 55, 66, 0.35);
    transition: .2s ease-out;
    font-size: .9em; }
  .btn-dashgroup[aria-expanded='true']::before {
    transform: translateY(-50%) rotate(180deg); }
  .btn-dashgroup:hover {
    color: #2F3742 !important;
    box-shadow: 0 1px 10px rgba(47, 55, 66, 0.03); }
    .btn-dashgroup:hover::before {
      color: rgba(47, 55, 66, 0.65); }
  .btn-dashgroup h5, .btn-dashgroup p {
    margin: 0;
    line-height: 1.1em; }
  .btn-dashgroup h5 {
    font-size: .95em; }
  .btn-dashgroup p {
    font-size: .8em;
    color: rgba(47, 55, 66, 0.7);
    text-align: right; }
  .btn-dashgroup > * {
    flex: 1 1 auto; }

.dashboards__inner {
  padding-top: 18px;
  padding-bottom: 45px; }

.dashthumbs {
  display: flex;
  flex-wrap: wrap;
  margin: -5px; }
  .dashthumbs .dashthumb {
    width: 20%;
    padding: 5px; }

.dashthumb {
  width: 20%;
  transition: all 350ms cubic-bezier(0.23, 1, 0.32, 1);
  position: relative; }
  .dashthumb:hover {
    z-index: 10;
    transform: translateY(-2px); }
    .dashthumb:hover .dashthumb__img {
      box-shadow: 0 2px 12px rgba(47, 55, 66, 0.15); }
    .dashthumb:hover .dashthumb__title {
      padding-top: 65px;
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
      border-radius: 6px; }
    .dashthumb:hover .btn-thumbactions {
      background-color: rgba(255, 255, 255, 0.75);
      color: #2F3742; }
  .dashthumb > a {
    display: block;
    position: relative; }

.dashthumb__img {
  padding-top: 71.69811320754717%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 6px;
  transition: .25s ease-out; }

.dashthumb__title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 45px 50px 12px 10px;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.55) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.55) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.55) 100%);
  border-radius: 6px;
  transition: .25s ease-out; }
  .dashthumb__title h5 {
    margin: 0;
    line-height: 1.1em;
    color: #fff;
    font-size: .85em;
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 1px; }

.dashthumb__actions {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 12px 10px;
  z-index: 2; }
  .dashthumb__actions .dropdown-menu {
    top: auto !important;
    left: auto !important;
    right: 10px !important;
    bottom: 42px !important;
    transform: translateY(7px) !important; }
  .dashthumb__actions .dropdown-item {
    font-size: .9em;
    padding: 9px 20px; }
  .dashthumb__actions.show .dropdown-menu {
    transform: translateY(0) !important; }

.btn-thumbactions {
  display: inline-block;
  padding: 7px 15px;
  border-radius: 25px;
  color: #fff;
  line-height: 1em; }

.dashthumb--disabled {
  opacity: .6; }
  .dashthumb--disabled .dashthumb__title {
    background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.65) 100%);
    background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.65) 100%);
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.65) 100%);
    left: 0px;
    bottom: 0px;
    right: 0px;
    width: auto; }
  .dashthumb--disabled a {
    cursor: default; }
  .dashthumb--disabled:hover {
    cursor: default;
    transform: translateY(0); }
    .dashthumb--disabled:hover .dashthumb__title {
      padding: 45px 50px 12px 10px;
      background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.65) 100%);
      background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.65) 100%);
      background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.65) 100%); }
    .dashthumb--disabled:hover .dashthumb__img {
      box-shadow: none; }
  .dashthumb--disabled .btn-thumbactions {
    display: none; }
  .dashthumb--disabled .dashthumb__img {
    filter: grayscale(100%); }

.dashthumb--locked {
  position: relative; }
  .dashthumb--locked::before {
    content: "\e902";
    font-family: "aheadicon";
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #fff;
    z-index: 5;
    width: 32px;
    height: 32px;
    line-height: 30px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.45);
    text-align: center;
    pointer-events: none; }
  .dashthumb--locked:hover .dashthumb__title h5, .dashthumb--locked:hover .dashthumb__title p {
    transform: translateY(-28px); }
  .dashthumb--locked:hover .dashthumb__title p {
    visibility: visible;
    opacity: .6; }
  .dashthumb--locked .dashthumb__title h5 {
    transition: .2s ease-out; }
  .dashthumb--locked .dashthumb__title p {
    font-size: 12px;
    color: #fff;
    margin: 0;
    opacity: .5;
    transition: .2s ease-out;
    height: 0;
    opacity: 0;
    visibility: hidden; }
  .dashthumb--locked .btn-thumbactions {
    display: none; }

.new {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  padding: 25px;
  background-color: #fff;
  text-align: center;
  border-top: 1px solid transparent;
  transition: all 320ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-delay: .15s;
  max-height: 0;
  transform: scale(1.08) translateY(-10px);
  overflow: hidden;
  opacity: 0;
  visibility: hidden; }
  .new p {
    font-size: .9em; }
  .new.show {
    max-height: 280px;
    opacity: 1;
    visibility: visible;
    box-shadow: 0 9px 12px rgba(47, 55, 66, 0.12);
    transform: scale(1) translateY(0);
    border-top-color: rgba(236, 237, 239, 0.5);
    transition-delay: 0s; }

.new__blocks {
  max-width: 650px;
  margin: 0 auto;
  display: flex; }
  .new__blocks > * {
    flex: 1;
    border: 1px solid rgba(47, 55, 66, 0.1);
    background-color: rgba(236, 237, 239, 0.65);
    margin: 0 5px;
    padding: 25px 15px;
    color: #2F3742;
    position: relative; }
    .new__blocks > *:hover {
      color: #09A709;
      border-color: #09A709;
      background-color: rgba(236, 237, 239, 0.35);
      box-shadow: inset 0 0 0 8px #fff;
      transform: scale(1.02); }
    .new__blocks > * i {
      font-size: 1.8em; }
    .new__blocks > * h5 {
      font-size: .9em;
      margin: 12px 0; }
    .new__blocks > * p {
      max-width: 120px;
      margin: 0 auto;
      opacity: .7;
      color: #2F3742;
      font-size: .8em; }

/*---- Users ----*/
.useritem {
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 1px 8px rgba(47, 55, 66, 0.08);
  margin-bottom: 7px;
  transition: .2s all;
  position: relative; }
  .useritem::after {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    background-color: rgba(9, 167, 9, 0.35);
    transition: .15s ease-out; }
  .useritem:hover {
    box-shadow: 0 2px 12px rgba(47, 55, 66, 0.2);
    transform: translateY(-2px);
    z-index: 4; }
    .useritem:hover .useritem__actions .btn-action {
      background-color: rgba(236, 237, 239, 0.5); }
      .useritem:hover .useritem__actions .btn-action:hover {
        background-color: rgba(47, 55, 66, 0.55);
        color: #fff; }
    .useritem:hover .useritem__actions .btn-delete:hover {
      background-color: #F24738;
      color: #fff; }
  .useritem > * {
    flex: 0 0 auto; }
  .useritem .btn-text {
    font-size: .75em;
    font-weight: 700; }

.useritem__label {
  font-size: .8em;
  opacity: .5; }

.useritem__large {
  font-size: 1.65em; }

.useritem__large--override {
  color: #FE825A; }
  .useritem__large--override span {
    font-size: .7em;
    color: rgba(47, 55, 66, 0.5); }

.useritem__opts {
  width: 200px; }
  .useritem__opts p {
    font-size: .85em;
    margin: 0; }
  .useritem__opts .grey {
    color: rgba(47, 55, 66, 0.55); }

.useritem--selected::after {
  width: 3px; }

.gu-mirror {
  display: flex; }
  .gu-mirror .useritem__pic {
    width: 130px;
    padding: 8px 20px;
    padding-right: 0; }
    .gu-mirror .useritem__pic img {
      width: 100%;
      height: auto; }
  .gu-mirror .useritem__points,
  .gu-mirror .useritem__score {
    width: 110px; }
  .gu-mirror .useritem__actions {
    width: 70px; }
  .gu-mirror .form-group > label {
    font-size: 13px; }

.useritem__check {
  padding: 0 10px;
  width: 35px; }
  .useritem__check .custom-control {
    min-height: inherit;
    padding: 0; }
  .useritem__check .custom-control-input {
    display: none; }
  .useritem__check .custom-control-label {
    line-height: 1em;
    margin: 0; }
    .useritem__check .custom-control-label::before, .useritem__check .custom-control-label::after {
      left: 0;
      top: 0; }

.useritem__drag {
  padding: 0 10px;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center; }
  .useritem__drag i {
    opacity: .35;
    pointer-events: none;
    display: none; }

.userslist--draggable .useritem__drag {
  cursor: move;
  display: flex; }
  .userslist--draggable .useritem__drag i {
    display: block; }

.useritem__pic {
  width: 90px;
  padding: 8px 20px; }
  .useritem__pic .avatar {
    width: 50px;
    height: 50px; }

.useritem__title {
  flex: 1 1 auto;
  padding: 10px 15px; }
  .useritem__title h5 {
    font-size: 1em;
    margin: 0; }
  .useritem__title p {
    margin: 0; }
  .useritem__title .useritem__email {
    font-size: .85em;
    margin-top: 3px;
    opacity: .7; }
  .useritem__title a {
    color: #2F3742; }

.useritem__pbox {
  background-color: rgba(47, 55, 66, 0.04);
  margin: 0 4px;
  border-radius: 3px;
  padding: 4px; }

.useritem__id {
  width: 80px;
  text-align: left;
  padding: 0 12px; }

.useritem__type {
  width: 120px;
  text-align: center; }
  .useritem__type p {
    margin: 0; }

.useritem__actions {
  width: 150px;
  text-align: right;
  padding-right: 20px; }
  .useritem__actions .dropdown {
    display: inline-block; }
  .useritem__actions .btn-action {
    margin-left: 2px; }

.useritem__override {
  width: 120px;
  padding-left: 12px; }

.useritem__points {
  width: 110px;
  text-align: center; }
  .useritem__points .form-group {
    margin-bottom: 6px; }
  .useritem__points .checbox-switch .label {
    font-size: .8em; }
  .useritem__points p {
    margin: 0; }

.useritem__score {
  width: 110px;
  text-align: center; }
  .useritem__score .form-group {
    margin-bottom: 6px; }
  .useritem__score .checbox-switch .label {
    font-size: .8em; }
  .useritem__score p {
    margin: 0; }

.userslist--dash .useritem__pic {
  width: 90px;
  padding-right: 0;
  text-align: center; }
  .userslist--dash .useritem__pic img {
    width: 100%;
    height: auto;
    border-radius: 5px; }

.userslist--dash .useritem__actions {
  width: 150px; }

.userslist--play .useritem__pic {
  width: 120px;
  padding-right: 0;
  text-align: center; }
  .userslist--play .useritem__pic img {
    width: 100%;
    height: auto;
    border-radius: 5px; }

.userslist--play .useritem__override {
  width: 80px; }

.userslist--play .useritem__actions {
  width: 80px; }

.userslist--selectable .useritem {
  position: relative; }
  .userslist--selectable .useritem:hover {
    cursor: pointer; }
  .userslist--selectable .useritem::before {
    content: "\e915";
    font-family: "aheadicon";
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    background-color: #00C853;
    color: #fff;
    text-align: center;
    font-size: 8px;
    transform: scale(0.2);
    opacity: 0;
    transition: all 360ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.userslist--selectable .useritem--selected {
  background-color: rgba(47, 55, 66, 0.05);
  box-shadow: none; }
  .userslist--selectable .useritem--selected::before {
    transform: scale(1);
    opacity: 1; }

.userslist--selectable .useritem__pic {
  padding-right: 0;
  width: 70px; }

.userslist--selectable .useritem__title {
  padding-right: 35px; }
  .userslist--selectable .useritem__title h5 {
    font-size: .85em; }
    .userslist--selectable .useritem__title h5 + p {
      margin-top: 3px; }
  .userslist--selectable .useritem__title p {
    font-size: .8em;
    opacity: .5; }

.userlist--cars .useritem__pic .player {
  transform: translateY(-15%); }

.badge--viewer {
  background-color: #ffe34b; }

.badge--manager {
  background-color: #fe914a;
  color: #fff; }

.badge--admin {
  background-color: #3977b4;
  color: #fff; }

.badge--super {
  background-color: #55c255;
  color: #fff; }

.badge--user {
  border: 1px solid rgba(9, 167, 9, 0.5);
  color: rgba(9, 167, 9, 0.8);
  font-size: .7em;
  padding: 3px 5px; }

.pane {
  background-color: #fff;
  box-shadow: 0 2px 16px rgba(47, 55, 66, 0.05);
  position: relative; }
  .pane .btn-close {
    position: absolute;
    right: 0;
    top: 0; }

.pane__head {
  text-align: center;
  padding: 20px 35px;
  border-bottom: 1px solid rgba(47, 55, 66, 0.07); }
  .pane__head + .pane__body {
    padding-top: 10px; }
  .pane__head h5 {
    font-size: 1.05em;
    margin: 0; }
    .pane__head h5 + p {
      margin-top: 8px; }

.pane__ctrl {
  display: flex;
  align-items: flex-end;
  text-align: left; }
  .pane__ctrl > * {
    flex: 1 1 auto; }
  .pane__ctrl p {
    margin: 0;
    font-size: .85em; }

.pane__actions {
  display: flex;
  justify-content: flex-end; }
  .pane__actions .btn {
    margin-left: 5px; }
  .pane__actions .btn-delete {
    background-color: #F24738; }
    .pane__actions .btn-delete:hover {
      background-color: #f3594b; }

.pane__body {
  padding: 25px 35px; }

.pane__body--nopadding {
  padding: 0 !important;
  position: relative; }

.pane__inner {
  max-width: 360px;
  margin: 0 auto; }

.pane--negative {
  margin: -30px -33px; }

.pane--fixed {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 2px;
  top: 0;
  bottom: 0;
  right: 0; }
  .pane--fixed .pane__body {
    flex: 1 1 auto;
    overflow-y: auto; }

.pane--dashboards .pane__head {
  padding-top: 35px; }

.pane--dashboards .pane__body {
  padding-top: 30px;
  position: relative; }

.list-users {
  height: 180px;
  overflow-y: auto;
  border: 1px solid rgba(47, 55, 66, 0.1);
  border-radius: 3px; }

.list-users--longer {
  height: 300px; }

.user {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  padding-right: 35px;
  color: #2F3742;
  position: relative; }
  .user::after {
    content: "\e915";
    font-family: "aheadicon";
    position: absolute;
    right: 8px;
    top: 50%;
    width: 18px;
    height: 18px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    background-color: #00C853;
    color: #fff;
    transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translateY(-50%) scale(0.2);
    opacity: 0;
    font-size: 9px; }
  .user:hover {
    background-color: rgba(47, 55, 66, 0.04);
    color: #2F3742; }
  .user.active {
    background-color: rgba(0, 200, 83, 0.1); }
    .user.active::after {
      opacity: 1;
      transform: translateY(-50%) scale(1); }
  .user .avatar {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    flex: 0 0 auto; }
  .user p {
    margin: 0;
    font-size: .8em; }
  .user .small {
    font-size: .75em;
    opacity: .8;
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.user__name {
  flex: 1 1 auto;
  min-width: 0; }

.user__title {
  font-weight: 700; }

.list-users--dash .user .avatar {
  width: 80px;
  height: 50px;
  border-radius: 3px; }
  .list-users--dash .user .avatar img {
    width: 80px;
    height: 50px;
    object-fit: cover;
    object-position: center; }

.list-users--dash .user .small {
  margin-top: 2px; }

/*---- Player Editor ----*/
.playered {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0; }

.playered__side {
  background-color: rgba(237, 240, 241, 0.3);
  width: 175px;
  flex: 0 0 auto;
  position: relative; }
  .playered__side::-webkit-scrollbar {
    width: 9px;
    height: 9px; }
  .playered__side::-webkit-scrollbar-track {
    background-color: transparent; }
  .playered__side::-webkit-scrollbar-thumb {
    background-color: rgba(47, 55, 66, 0.15);
    border-radius: 12px;
    border: 3px solid #FAFBFB; }
  .playered__side .dropdown {
    position: static; }
    .playered__side .dropdown + .dropdown {
      margin-top: -5px; }
      .playered__side .dropdown + .dropdown .dropdown-toggle {
        margin-top: 0; }
    .playered__side .dropdown.show .dropdown-menu {
      box-shadow: 4px 0 8px rgba(47, 55, 66, 0.05); }
    .playered__side .dropdown.show .dropdown-toggle {
      box-shadow: none;
      background-color: #fff;
      color: rgba(47, 55, 66, 0.55);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
      .playered__side .dropdown.show .dropdown-toggle::before {
        background-color: #fff; }
      .playered__side .dropdown.show .dropdown-toggle::after {
        opacity: 0; }
    .playered__side .dropdown.disabled {
      opacity: .3; }
      .playered__side .dropdown.disabled .dropdown-toggle::after {
        display: none; }
      .playered__side .dropdown.disabled .dropdown-toggle:hover {
        cursor: default; }
        .playered__side .dropdown.disabled .dropdown-toggle:hover::before {
          background-color: transparent; }
  .playered__side .dropdown-toggle, .playered__side .dropdown-toggle:active, .playered__side .dropdown-toggle:focus {
    display: block;
    background-color: transparent;
    color: rgba(47, 55, 66, 0.15);
    width: calc(100% - 20px);
    position: relative;
    box-shadow: none !important;
    border: none !important;
    border-radius: 7px; }
    .playered__side .dropdown-toggle img, .playered__side .dropdown-toggle svg, .playered__side .dropdown-toggle:active img, .playered__side .dropdown-toggle:active svg, .playered__side .dropdown-toggle:focus img, .playered__side .dropdown-toggle:focus svg {
      width: 70px;
      height: auto; }
    .playered__side .dropdown-toggle:hover, .playered__side .dropdown-toggle:active:hover, .playered__side .dropdown-toggle:focus:hover {
      background-color: #fff;
      color: rgba(47, 55, 66, 0.55); }
      .playered__side .dropdown-toggle:hover::after, .playered__side .dropdown-toggle:active:hover::after, .playered__side .dropdown-toggle:focus:hover::after {
        right: 2px; }
    .playered__side .dropdown-toggle::after, .playered__side .dropdown-toggle:active::after, .playered__side .dropdown-toggle:focus::after {
      content: "\e908";
      font-family: "aheadicon";
      position: absolute;
      right: 7px;
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);
      font-size: 10px;
      width: auto;
      height: auto;
      margin: 0;
      border: none;
      transition: .2s all; }
    .playered__side .dropdown-toggle::before, .playered__side .dropdown-toggle:active::before, .playered__side .dropdown-toggle:focus::before {
      content: "";
      display: inline-block;
      width: 10px;
      position: absolute;
      left: 100%;
      top: 0;
      height: 100%;
      transition: .2s all; }
  .playered__side .dropdown-menu {
    width: 175px;
    left: 100% !important;
    top: 0 !important;
    bottom: 0 !important;
    transform: none !important;
    box-shadow: none;
    overflow-y: auto; }
    .playered__side .dropdown-menu::-webkit-scrollbar {
      width: 9px;
      height: 9px; }
    .playered__side .dropdown-menu::-webkit-scrollbar-track {
      background-color: transparent; }
    .playered__side .dropdown-menu::-webkit-scrollbar-thumb {
      background-color: rgba(47, 55, 66, 0.15);
      border-radius: 12px;
      border: 3px solid #fff; }
    .playered__side .dropdown-menu .dropdown-item, .playered__side .dropdown-menu .dropdown-item:focus, .playered__side .dropdown-menu .dropdown-item:active {
      margin: 10px;
      width: auto;
      border-radius: 7px;
      position: relative;
      background-color: transparent; }
      .playered__side .dropdown-menu .dropdown-item::before, .playered__side .dropdown-menu .dropdown-item:focus::before, .playered__side .dropdown-menu .dropdown-item:active::before {
        content: "\e915";
        font-family: "aheadicon";
        position: absolute;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
        background-color: #00C853;
        color: #fff;
        text-align: center;
        font-size: 8px;
        right: 5px;
        top: 5px;
        transform: scale(0.2);
        opacity: 0;
        transition: all 360ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
      .playered__side .dropdown-menu .dropdown-item.active, .playered__side .dropdown-menu .dropdown-item:focus.active, .playered__side .dropdown-menu .dropdown-item:active.active {
        background-color: rgba(237, 240, 241, 0.3); }
        .playered__side .dropdown-menu .dropdown-item.active::before, .playered__side .dropdown-menu .dropdown-item:focus.active::before, .playered__side .dropdown-menu .dropdown-item:active.active::before {
          transform: scale(1);
          opacity: 1; }
      .playered__side .dropdown-menu .dropdown-item img, .playered__side .dropdown-menu .dropdown-item svg, .playered__side .dropdown-menu .dropdown-item:focus img, .playered__side .dropdown-menu .dropdown-item:focus svg, .playered__side .dropdown-menu .dropdown-item:active img, .playered__side .dropdown-menu .dropdown-item:active svg {
        width: 100%;
        height: auto; }
      .playered__side .dropdown-menu .dropdown-item:hover, .playered__side .dropdown-menu .dropdown-item:focus:hover, .playered__side .dropdown-menu .dropdown-item:active:hover {
        background-color: rgba(237, 240, 241, 0.3); }

.dropdown--colors .dropdown-menu {
  width: 200px;
  padding: 22px 35px; }

.dropdown-head {
  margin-top: 30px;
  margin-bottom: 18px;
  font-size: 13px;
  font-weight: 700;
  border-bottom: 1px solid rgba(237, 240, 241, 0.5);
  height: 10px;
  color: rgba(47, 55, 66, 0.65); }
  .dropdown-head span {
    background-color: #fff;
    padding-right: 8px; }

.playered__content {
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 20px; }

.player-preview {
  width: 300px; }
  .player-preview svg {
    width: 100%;
    height: auto; }

.playered__content--cars .player-preview {
  margin-top: -100px; }

.playered__player {
  text-align: center; }
  .playered__player img {
    max-width: 100%;
    height: auto; }

.cpicker {
  margin-bottom: 12px; }
  .cpicker p {
    margin: 0;
    margin-bottom: 2px;
    font-size: 13px; }

.cpicker__inner {
  display: flex;
  align-items: flex-start; }
  .cpicker__inner .form-control {
    flex: 1 1 auto;
    margin-left: 8px;
    border-radius: 0 !important;
    height: 30px !important;
    box-shadow: none !important;
    border: none !important;
    border-bottom: 1px solid rgba(47, 55, 66, 0.1) !important;
    text-align: center;
    max-width: 80px;
    padding-left: 0 !important;
    padding-right: 0 !important; }
    .cpicker__inner .form-control:focus {
      border-bottom-color: rgba(47, 55, 66, 0.35) !important; }
    .cpicker__inner .form-control[disabled] {
      background-color: transparent;
      color: rgba(47, 55, 66, 0.5); }

.cpicker__swatch {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  flex: 0 0 auto;
  background-color: rgba(237, 240, 241, 0.3);
  transition: .2s ease-out;
  margin-top: 4px; }
  .cpicker__swatch:hover {
    box-shadow: 0 0 0 3px rgba(237, 240, 241, 0.45); }

.player {
  display: inline-block;
  position: relative; }
  .player > *:not(:first-child) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .player svg {
    width: 100% !important;
    height: auto !important; }

.playered__content-center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .playered__content-center .player {
    width: 300px; }

.horse-rider {
  position: relative; }
  .horse-rider > *:not(:first-child) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }

/* Tables */
.table {
  font-size: .825em;
  margin: 0; }
  .table p {
    margin: 0;
    line-height: 1.1em; }
  .table .large {
    font-size: 1.15em;
    margin-bottom: 3px; }
  .table h5 {
    font-size: 1.15em;
    margin: 0;
    margin-bottom: 6px;
    line-height: 1.2em; }
  .table .circle {
    width: 28px;
    height: 28px;
    line-height: 28px;
    margin: -5px 0; }
    .table .circle [class^='icon-'] {
      line-height: 28px; }
  .table .delimited {
    margin-bottom: 6px; }
  .table .list-tags > li {
    background-color: rgba(47, 55, 66, 0.06);
    color: rgba(47, 55, 66, 0.65); }
  .table .list-actions {
    margin-bottom: 11px; }
    .table .list-actions > li {
      padding: 0;
      vertical-align: top; }
  .table .table__output a {
    color: #2F3742; }
    .table .table__output a:hover {
      color: #09A709; }
  .table .table__output .icon-action {
    margin-right: 6px;
    opacity: .5;
    position: relative;
    top: 1px; }
  .table > thead > tr th {
    font-size: .9em;
    color: rgba(9, 167, 9, 0.7);
    padding: 8px 18px;
    border-bottom: none; }
  .table > tbody > tr:hover .table__inlineactions {
    opacity: 1;
    visibility: visible; }
  .table > tbody > tr > td {
    padding: 20px 18px;
    line-height: 1.2em;
    border-top: 1px solid rgba(47, 55, 66, 0.03); }
    .table > tbody > tr > td.tbl--larger {
      font-size: 1.15em; }
    .table > tbody > tr > td.tbl--icon {
      width: 40px;
      padding-left: 8px !important;
      padding-right: 4px !important; }
  .table > tbody > tr.row-published .circle {
    background-color: rgba(114, 215, 233, 0.2);
    color: #72D7E9;
    box-shadow: none; }
  .table > tbody > tr.row--expired {
    background-color: rgba(236, 237, 239, 0.5);
    color: rgba(47, 55, 66, 0.55); }
    .table > tbody > tr.row--expired .list-tags > li {
      background-color: rgba(47, 55, 66, 0.3);
      color: #fff; }
    .table > tbody > tr.row--expired .circle {
      box-shadow: none; }
      .table > tbody > tr.row--expired .circle [class^="icon-"] {
        color: rgba(47, 55, 66, 0.4); }
    .table > tbody > tr.row--expired .barbg .bar {
      background-color: rgba(47, 55, 66, 0.3); }
  .table .table__highlight {
    font-weight: 700; }
  .table .table__actions {
    text-align: right;
    position: relative; }
  .table.table--highlight > tbody > tr {
    transition: all 0.2s linear; }
    .table.table--highlight > tbody > tr:hover {
      background-color: rgba(236, 237, 239, 0.5); }
      .table.table--highlight > tbody > tr:hover .table__drop {
        opacity: 1;
        visibility: visible; }
  .table.table--centered > tbody > tr > td {
    vertical-align: middle; }
  .table.table--largertype {
    font-size: .9em; }
  .table.table--tight > tbody > tr > td {
    padding: 12px 18px; }
  .table.table--tight .table__inlineactions {
    margin-top: -10px;
    margin-bottom: -10px; }
  .table .table__inlineactions {
    transition: all 0.2s linear;
    opacity: 0;
    visibility: hidden; }
    .table .table__inlineactions .list-inline {
      white-space: nowrap;
      margin: 0; }
      .table .table__inlineactions .list-inline > li {
        vertical-align: top;
        padding: 0;
        margin-left: -4px; }
        .table .table__inlineactions .list-inline > li a {
          display: block;
          padding: 7px 8px;
          line-height: 1em;
          color: rgba(38, 45, 57, 0.35);
          font-size: 18px;
          border-radius: 3px; }
          .table .table__inlineactions .list-inline > li a:hover {
            color: rgba(38, 45, 57, 0.85);
            background-color: #fff; }

/* Forms */
.form .form-control {
  transition: all 0.2s linear;
  font-size: 1em;
  box-shadow: none;
  border-radius: 3px;
  border: 1px solid rgba(47, 55, 66, 0.12);
  height: 38px;
  color: #2F3742;
  box-shadow: 0 2px 6px rgba(47, 55, 66, 0.05); }
  .form .form-control:focus {
    border-color: #8798A3;
    box-shadow: none; }
  .form .form-control.form-area {
    height: auto; }
  .form .form-control::-webkit-input-placeholder {
    color: rgba(47, 55, 66, 0.5); }
  .form .form-control::-moz-placeholder {
    color: rgba(47, 55, 66, 0.5); }
  .form .form-control:-ms-input-placeholder {
    color: rgba(47, 55, 66, 0.5); }
  .form .form-control:-moz-placeholder {
    color: rgba(47, 55, 66, 0.5); }

.form .form-group > label {
  font-weight: 400;
  font-size: 13px;
  color: rgba(47, 55, 66, 0.6);
  margin-bottom: 4px; }

.form .form-group .form__error {
  display: none;
  font-size: 13px;
  padding: 4px 0;
  color: #F24738; }

.form .form-group--line .form-control {
  border: none;
  box-shadow: none;
  border-bottom: 1px solid rgba(47, 55, 66, 0.2);
  border-radius: 0; }

.form .form-group--delimited {
  margin-top: 30px; }

.form .form-group--tight {
  margin-bottom: 5px; }

.form .has-error .form-control {
  border-color: #F24738; }

.form .has-error .form__error {
  display: block; }

.form .help-block {
  font-size: 13px;
  color: rgba(38, 45, 57, 0.55);
  margin-top: 7px; }

.form .form-group--innerlabel {
  position: relative; }
  .form .form-group--innerlabel > label {
    position: absolute;
    left: 12px;
    top: 11px;
    font-weight: 700;
    color: #2F3742; }
  .form .form-group--innerlabel .form-control {
    padding-left: 95px; }
    .form .form-group--innerlabel .form-control:focus {
      border-bottom-color: rgba(47, 55, 66, 0.5); }
  .form .form-group--innerlabel.form-group--line > label {
    left: 0; }
  .form .form-group--innerlabel.form-group--line .form-control {
    padding-left: 80px; }

.form-group--multibutton {
  display: flex;
  margin-left: -3px;
  margin-right: -3px; }
  .form-group--multibutton .btn {
    flex: 1;
    margin: 0 3px; }
  .form-group--multibutton .btn--primary {
    flex: 2.5; }

.form--prefix {
  position: relative; }
  .form--prefix .form__picto {
    position: absolute;
    left: 12px;
    top: 8px;
    opacity: .35; }
  .form--prefix .form-control {
    padding-left: 35px !important; }

.form-stepper {
  max-width: 100px;
  position: relative; }
  .form-stepper .form-control {
    height: 50px;
    text-align: center;
    padding-right: 40px !important;
    font-size: 1.4em; }
  .form-stepper .plus, .form-stepper .minus {
    width: 30px;
    border-left: 1px solid #ECEDEF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1em; }
    .form-stepper .plus:hover, .form-stepper .minus:hover {
      color: #2F3742;
      background-color: rgba(236, 237, 239, 0.5); }
  .form-stepper .minus {
    border-top: 1px solid #ECEDEF; }

.form-stepper__ctrl {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%; }

.custom-control-label {
  cursor: pointer; }
  .custom-control-label::before {
    top: .2rem; }
  .custom-control-label::after {
    top: .2rem; }

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #09A709;
  border-radius: 4px; }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: .5rem;
    background: #222; }
  .custom-switch .custom-control-label::after {
    top: calc(-2.25rem + 4px);
    left: calc(-2.25rem + 4px);
    width: calc(1rem - 6px);
    height: calc(1rem - 6px);
    background-color: #adb5bd;
    border-radius: .5rem;
    transition: all .15s ease-in-out; }
  .custom-switch .custom-control-input:active ~ .custom-control-label::before {
    background: #222; }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #09A709; }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    left: -21px; }

.overrides .form-stepper {
  max-width: inherit; }

.overrides__row {
  display: flex;
  align-items: center;
  padding: 17px 0; }
  .overrides__row + .overrides__row {
    border-top: 1px solid rgba(47, 55, 66, 0.1); }
  .overrides__row > * {
    flex: 0 0 auto;
    padding: 0 8px; }
  .overrides__row .form-group {
    margin: 0; }
  .overrides__row .help-block {
    margin-bottom: 0; }

.overrides__title {
  width: 200px;
  padding-left: 0; }
  .overrides__title h5 {
    font-size: 1em;
    margin: 0;
    line-height: 1.1em; }

.overrides__value {
  flex: 1 1 auto;
  text-align: left; }

.override__actions {
  width: 80px;
  padding-right: 0; }

.overrides__value--single p {
  font-size: .9em; }

.dash-duration {
  display: flex;
  align-items: center; }
  .dash-duration p {
    margin: 0;
    margin-right: 20px;
    margin-left: 8px;
    font-size: 1.1em; }

.dash-duration__msg {
  background-color: rgba(47, 55, 66, 0.08);
  padding: 9px 14px;
  text-align: center;
  border-radius: 3px; }

.empty {
  text-align: center;
  padding: 85px 30px;
  border-radius: 6px;
  background-color: rgba(47, 55, 66, 0.05);
  color: rgba(47, 55, 66, 0.65); }
  .empty p {
    margin: 0;
    line-height: 1.5em; }

.empty__picto {
  width: 100px;
  height: auto;
  margin-bottom: 20px; }

/*---- Footer ----*/
/* Scoreboard */
.racepage.modal-open .racetrack {
  transition: 1s ease-out;
  filter: blur(4px); }

.racepage.modal-open .modal-backdrop.show {
  opacity: .3; }

.modal-scoreboard .modal-content {
  background-color: transparent; }

.modal-tournamentscore {
  perspective: 2000px;
  display: block;
  visibility: visible;
  opacity: 1;
  transform: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  opacity: 0;
  transition: .8s ease-out; }
  .modal-tournamentscore .modal-content {
    background-color: transparent; }
  .modal-tournamentscore .btn-back {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 15;
    color: rgba(255, 255, 255, 0.65);
    padding: 12px; }
    .modal-tournamentscore .btn-back i {
      display: inline-block;
      transform: rotate(180deg);
      position: relative;
      top: 1px; }
    .modal-tournamentscore .btn-back:hover {
      color: #fff; }
  .modal-tournamentscore .scoreboard__head {
    transition: all 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: translateY(-20px);
    opacity: 0; }

.modal-scoreboard-container {
  max-width: 1000px;
  min-height: calc(100% - (1.75rem * 2));
  margin: 1.75rem auto;
  display: flex;
  align-items: center;
  position: relative;
  width: auto;
  pointer-events: none;
  z-index: 100; }

.modal-scoreboard-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0; }

.scoreboard__head {
  padding: 25px 40px;
  position: relative;
  background: #44bc44;
  background: -moz-linear-gradient(top, #44bc44 0%, #09a709 100%);
  background: -webkit-linear-gradient(top, #44bc44 0%, #09a709 100%);
  background: linear-gradient(to bottom, #44bc44 0%, #09a709 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#44bc44', endColorstr='#09a709',GradientType=0 );
  text-align: center;
  color: #fff;
  z-index: 10; }
  .scoreboard__head h2 {
    margin: 0;
    font-size: 1.4em; }

.scoreboard__aheadlogo {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%); }
  .scoreboard__aheadlogo img {
    width: 100px;
    height: auto; }

.scoreboard__user {
  margin-top: -40px; }
  .scoreboard__user .avatar {
    width: 70px;
    height: 70px;
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.25); }

.scoreboard__logo {
  position: absolute;
  left: 30px;
  top: 49%;
  transform: translateY(-50%);
  width: 120px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .scoreboard__logo img {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

.list-scores {
  perspective: 2000px; }
  .list-scores > li {
    display: flex;
    align-items: center;
    background: -moz-linear-gradient(top, rgba(10, 10, 10, 0.65) 0%, #0a0a0a 100%);
    background: -webkit-linear-gradient(top, rgba(10, 10, 10, 0.65) 0%, #0a0a0a 100%);
    background: linear-gradient(to bottom, rgba(10, 10, 10, 0.65) 0%, #0a0a0a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a60a0a0a', endColorstr='#0a0a0a',GradientType=0 );
    color: #fff;
    padding: 8px 25px;
    margin: 2px 0;
    transform: rotateX(-90deg) translateY(0) scale(1.05);
    transform-origin: 50% 50%;
    transition: all 650ms cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 350ms linear;
    opacity: 0; }
    .list-scores > li > * {
      flex: 0 0 auto; }
    .list-scores > li.active {
      opacity: 1;
      transform: rotateX(0) translateY(0) scale(1); }
    .list-scores > li.gold {
      background: #fef3a4;
      background: -moz-linear-gradient(top, #fef3a4 0%, #d1a814 100%);
      background: -webkit-linear-gradient(top, #fef3a4 0%, #d1a814 100%);
      background: linear-gradient(to bottom, #fef3a4 0%, #d1a814 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fef3a4', endColorstr='#d1a814',GradientType=0 );
      color: #111; }
      .list-scores > li.gold .no {
        color: #111;
        border-color: #111; }
      .list-scores > li.gold .list-scores__perc {
        background-color: #111 !important;
        color: #fff !important; }
    .list-scores > li.silver {
      background: #abb2b9;
      background: -moz-linear-gradient(top, #abb2b9 0%, #69757e 100%);
      background: -webkit-linear-gradient(top, #abb2b9 0%, #69757e 100%);
      background: linear-gradient(to bottom, #abb2b9 0%, #69757e 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#abb2b9', endColorstr='#69757e',GradientType=0 );
      color: #fff; }
      .list-scores > li.silver .no {
        color: #111;
        border-color: #111; }
      .list-scores > li.silver .list-scores__perc {
        background-color: #111 !important;
        color: #fff !important; }
    .list-scores > li.bronze {
      background: #d39981;
      background: -moz-linear-gradient(top, #d39981 1%, #ae6028 100%);
      background: -webkit-linear-gradient(top, #d39981 1%, #ae6028 100%);
      background: linear-gradient(to bottom, #d39981 1%, #ae6028 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d39981', endColorstr='#ae6028',GradientType=0 );
      color: #fff; }
      .list-scores > li.bronze .no {
        color: #111;
        border-color: #111; }
      .list-scores > li.bronze .list-scores__perc {
        background-color: #111 !important;
        color: #fff !important; }
    .list-scores > li.goldalt::before {
      content: "";
      display: inline-block;
      width: 36px;
      height: 36px;
      background-image: url(../images/winner-gold.svg);
      background-size: cover;
      position: absolute;
      left: -18px; }
    .list-scores > li.goldalt .no {
      border-color: #fef3a4;
      color: #fef3a4; }
    .list-scores > li.silveralt::before {
      content: "";
      display: inline-block;
      width: 36px;
      height: 36px;
      background-image: url(../images/winner-silver.svg);
      background-size: cover;
      position: absolute;
      left: -18px; }
    .list-scores > li.silveralt .no {
      border-color: #abb2b9;
      color: #abb2b9; }
    .list-scores > li.bronzealt::before {
      content: "";
      display: inline-block;
      width: 36px;
      height: 36px;
      background-image: url(../images/winner-bronze.svg);
      background-size: cover;
      position: absolute;
      left: -18px; }
    .list-scores > li.bronzealt .no {
      border-color: #d39981;
      color: #d39981; }
  .list-scores p {
    margin: 0;
    font-size: 1.3em;
    font-weight: 700; }
  .list-scores .no {
    width: 38px;
    height: 38px;
    line-height: 35px;
    border-radius: 50%;
    text-align: center;
    color: #FFD800;
    border: 2px solid #FFD800;
    flex: 0 0 auto;
    margin-right: 20px; }
  .list-scores .list-scores__sec {
    display: none;
    font-weight: 400;
    font-size: .82em;
    line-height: 1.1em;
    opacity: .65; }
  .list-scores .avatar {
    background-color: transparent;
    width: 38px;
    height: 38px;
    flex: 0 0 auto;
    margin-right: 12px; }
  .list-scores .list-scores__name {
    flex: 1 1 auto;
    padding-right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0; }
  .list-scores .list-scores__dept {
    width: 22%;
    padding-right: 20px;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .list-scores .list-scores__qualified {
    width: 85px;
    padding-right: 20px;
    text-align: center; }
  .list-scores .list-scores__val,
  .list-scores .list-scores__target {
    width: 15%;
    padding-right: 20px;
    text-align: right; }
  .list-scores .list-scores__perc {
    padding: 2px 6px;
    border-radius: 4px;
    width: 60px;
    text-align: center; }
  .list-scores .list-scores__perc--high {
    background: -moz-linear-gradient(top, rgba(9, 167, 9, 0.81) 0%, #09a709 100%);
    background: -webkit-linear-gradient(top, rgba(9, 167, 9, 0.81) 0%, #09a709 100%);
    background: linear-gradient(to bottom, rgba(9, 167, 9, 0.81) 0%, #09a709 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cf09a709', endColorstr='#09a709',GradientType=0 ); }
  .list-scores .list-scores__perc--med {
    background: -moz-linear-gradient(top, rgba(255, 216, 0, 0.81) 0%, #ffd800 100%);
    background: -webkit-linear-gradient(top, rgba(255, 216, 0, 0.81) 0%, #ffd800 100%);
    background: linear-gradient(to bottom, rgba(255, 216, 0, 0.81) 0%, #ffd800 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cfffd800', endColorstr='#ffd800',GradientType=0 ); }
  .list-scores .list-scores__perc--low {
    background: -moz-linear-gradient(top, rgba(255, 101, 0, 0.81) 0%, #ff6500 100%);
    background: -webkit-linear-gradient(top, rgba(255, 101, 0, 0.81) 0%, #ff6500 100%);
    background: linear-gradient(to bottom, rgba(255, 101, 0, 0.81) 0%, #ff6500 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cfff6500', endColorstr='#ff6500',GradientType=0 ); }
  .list-scores .list-scores__perc--lowest {
    background: -moz-linear-gradient(top, rgba(241, 0, 12, 0.81) 0%, #f1000c 100%);
    background: -webkit-linear-gradient(top, rgba(241, 0, 12, 0.81) 0%, #f1000c 100%);
    background: linear-gradient(to bottom, rgba(241, 0, 12, 0.81) 0%, #f1000c 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cff1000c', endColorstr='#f1000c',GradientType=0 ); }
  .list-scores .list-scores__trend {
    width: 35px;
    text-align: right; }
    .list-scores .list-scores__trend .aheadicon-arrow {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      color: #f6d000;
      background-color: #fff;
      border-radius: 50%;
      font-size: .85em; }
    .list-scores .list-scores__trend .aheadicon-arrow--up {
      transform: rotate(-45deg);
      color: #2F3742;
      background-color: rgba(0, 200, 83, 0.7); }
    .list-scores .list-scores__trend .aheadicon-arrow--dn {
      transform: rotate(45deg);
      color: #2F3742;
      background-color: rgba(242, 71, 56, 0.7); }
  .list-scores .list-scores__head p {
    font-size: 14px;
    opacity: .65; }
  .list-scores .list-scores__head .no,
  .list-scores .list-scores__head .avatar {
    border: none;
    height: 10px; }
  .list-scores .list-scores__title {
    background-color: #033d03;
    background-image: none; }
    .list-scores .list-scores__title .list-scores__name {
      font-size: 1.25em;
      padding-left: 58px; }

.list-scores--altanim {
  margin: 0; }
  .list-scores--altanim > li {
    transform: rotateY(50deg) translateX(13%) scale(0.85); }
    .list-scores--altanim > li.active {
      transform: rotateY(0) translateY(0) scale(1); }
  .list-scores--altanim.animatable {
    position: relative; }
    .list-scores--altanim.animatable > li {
      transition: all 800ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }
      .list-scores--altanim.animatable > li:not(.list-list-scores__head) {
        position: absolute;
        left: 0;
        width: 100%; }

.scoreboard-tournament-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 180vw;
  height: 100%;
  z-index: 1;
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s, transform 60s;
  transition-timing-function: opacity linear, transform ease-in-out; }

.scoreboard-tournament .modal-tournamentscore {
  opacity: 1; }
  .scoreboard-tournament .modal-tournamentscore .scoreboard__head {
    transform: translateY(0);
    opacity: 1; }

.scoreboard-tournament .scoreboard-tournament-bg {
  opacity: 1;
  visibility: visible; }
  .scoreboard-tournament .scoreboard-tournament-bg.animate {
    transform: translateX(-80vw); }

.scoreboard-tournament-off .list-scores--altanim > li {
  transform: rotateY(0) translateY(0) scale(1) !important;
  opacity: 0; }

.scoreboard-tournament-off .scoreboard__head {
  transform: none; }

.scoreboard-tournament-off .modal-scoreboard-content {
  transition: all 0.7s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transform: translateY(80px); }

.scoreboard__nav .btn {
  padding: 0;
  display: flex;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.35);
  color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }
  .scoreboard__nav .btn:hover {
    background-color: rgba(0, 0, 0, 0.65);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.65);
    color: #fff; }

.scoreboard__nav .btn-prev {
  left: 5px; }
  .scoreboard__nav .btn-prev i {
    display: inline-block;
    transform: rotate(180deg); }

.scoreboard__nav .btn-next {
  right: 5px; }

.scoreboard--user .scoreboard__head {
  background: #ff6e14;
  background: -moz-linear-gradient(top, #ff6e14 0%, #d63d06 100%);
  background: -webkit-linear-gradient(top, #ff6e14 0%, #d63d06 100%);
  background: linear-gradient(to bottom, #ff6e14 0%, #d63d06 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff6e14', endColorstr='#d63d06',GradientType=0 ); }
  .scoreboard--user .scoreboard__head h2 {
    font-size: 2.4em; }
    .scoreboard--user .scoreboard__head h2 span {
      display: block;
      font-size: 18px; }

.scoreboard--user .list-scores .list-scores__title {
  background-color: #702900; }
  .scoreboard--user .list-scores .list-scores__title .list-scores__name {
    padding-left: 0; }

.scoreboard--tour .scoreboard__head {
  background: #43657c;
  background: -moz-linear-gradient(top, #43657c 0%, #274760 100%);
  background: -webkit-linear-gradient(top, #43657c 0%, #274760 100%);
  background: linear-gradient(to bottom, #43657c 0%, #274760 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#43657c', endColorstr='#274760',GradientType=0 ); }

.scoreboard--tour .list-scores > li {
  background: -moz-linear-gradient(top, white 0%, white 1%, rgba(255, 255, 255, 0.87) 100%);
  background: -webkit-linear-gradient(top, white 0%, white 1%, rgba(255, 255, 255, 0.87) 100%);
  background: linear-gradient(to bottom, white 0%, white 1%, rgba(255, 255, 255, 0.87) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#deffffff',GradientType=0 );
  color: #2F3742;
  padding-top: 4px;
  padding-bottom: 4px; }

.scoreboard--tour .list-scores .list-scores__head {
  background-image: none;
  background-color: #223747;
  color: #fff;
  padding-top: 0;
  padding-bottom: 0; }

.scoreboard--tour .list-scores .list-scores__perc {
  padding-top: 0;
  padding-bottom: 0; }

.scoreboard--tour .list-scores .list-scores__perc--lowest,
.scoreboard--tour .list-scores .list-scores__perc--low {
  color: #fff; }

.scoreboard--tour .list-scores .list-scores__trend .aheadicon-arrow {
  width: 28px;
  height: 28px; }

.scoreboard--tour .list-scores .no {
  border-color: #43657c;
  color: #43657c;
  width: 35px;
  height: 35px;
  line-height: 32px; }

.scoreboard--tour .list-scores .avatar {
  width: 35px;
  height: 35px; }

/* Countdown */
.countdown {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.25);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1); }
  .countdown.in {
    transition: all 900ms cubic-bezier(0.23, 1, 0.32, 1);
    opacity: 1;
    visibility: visible;
    transform: scale(1); }
  .countdown.out {
    transition: all 500ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
    opacity: 0;
    visibility: hidden;
    transition-delay: .25s; }
    .countdown.out p {
      transition: all 500ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translateY(100px);
      opacity: 0; }

.countdown__inner {
  text-align: center;
  max-width: 300px;
  color: #fff; }
  .countdown__inner p {
    margin: 0;
    font-size: 2.4em;
    line-height: 1.15em; }
  .countdown__inner .countdown__time {
    font-size: 7em;
    font-weight: 700; }

/*---- Spinner ----*/
@keyframes spinner {
  to {
    transform: rotate(360deg); } }

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border-top: 2px solid #09A709;
  border-right: 2px solid transparent;
  animation: spinner .6s linear infinite; }

/*---- Misc ----*/
.tooltip {
  margin-top: -15px; }
  .tooltip.fade {
    transition: opacity .15s linear, margin .2s ease-out; }
  .tooltip.show {
    opacity: 1;
    margin-top: -20px; }
  .tooltip > .tooltip-inner {
    background-color: #fff;
    color: #222;
    line-height: 1.3em;
    box-shadow: 0 6px 18px rgba(47, 55, 66, 0.06);
    padding: 13px 20px; }

.tooltip.top .tooltip-arrow {
  display: none; }

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #fff; }

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #fff; }

/* .racepage {

  .tooltip-inner {
    padding: 6px 12px;
    font-weight: 700;
    background-color: rgba(145, 149, 151, .85);
  }

  .bs-tooltip-top {

    .arrow::before {
      border-top-color: rgba(145, 149, 151, .85);
    }
  }
} */
/* Round crop mask */
.ReactCrop__crop-selection {
  border-radius: 50%; }

.ReactCrop__crop-selection--square .ReactCrop__crop-selection {
  border-radius: 3px; }

.ReactCrop__image {
  max-height: 450px;
  border-radius: 6px; }

/* Toast */
#toast-container > div,
#toast-container > div:hover {
  box-shadow: none; }

/* Color Palette Styles */
.sp-container {
  background-color: #fff;
  border: none;
  border-radius: 4px;
  box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.1); }

.sp-palette .sp-thumb-el {
  margin: 0;
  border: none;
  width: 20px;
  height: 20px; }

.sp-palette .sp-thumb-dark.sp-thumb-active .sp-thumb-inner {
  background-image: none; }

.sp-container button {
  background-image: none !important;
  background-color: #ddd;
  border: none !important;
  text-shadow: none;
  margin-top: 3px;
  font-family: Open Sans, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 12px;
  transition: all 0.2s linear;
  outline: none; }
  .sp-container button:hover {
    background-color: #ccc;
    color: #fff; }

/* Checkbox switch */
.checkbox.checbox-switch {
  padding-left: 0;
  text-align: left; }

.checbox-switch .label {
  font-size: .9em; }

.checkbox.checbox-switch label,
.checkbox-inline.checbox-switch {
  display: inline-block;
  position: relative;
  padding-left: 0; }

.checkbox.checbox-switch label input,
.checkbox-inline.checbox-switch input {
  display: none; }

.checkbox.checbox-switch label .dot,
.checkbox-inline.checbox-switch .dot {
  width: 30px;
  border-radius: 20px;
  height: 16px;
  border: 1px solid #ddd;
  background-color: #ddd;
  border-color: #ddd;
  transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s;
  display: inline-block;
  vertical-align: middle;
  margin-right: 2px; }

.checkbox.checbox-switch label .dot:before,
.checkbox-inline.checbox-switch .dot:before {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: white;
  content: " ";
  position: relative;
  left: 0px;
  transition: all 0.2s ease;
  top: -4px; }

.checkbox.checbox-switch label > input:checked + .dot:before,
.checkbox-inline.checbox-switch > input:checked + .dot:before {
  left: 14px; }

/* Switch Primary */
.checkbox.checbox-switch.switch-primary label > input:checked + .dot,
.checkbox-inline.checbox-switch.switch-primary > input:checked + .dot {
  background-color: #00C853;
  border-color: #00C853;
  /*box-shadow: rgb(0, 105, 217) 0px 0px 0px 8px inset;*/
  transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s; }

.checkbox.checbox-switch.switch-primary label > input:checked:disabled + .dot,
.checkbox-inline.checbox-switch.switch-primary > input:checked:disabled + .dot {
  background-color: red;
  border-color: red;
  /* box-shadow: rgb(109, 163, 221) 0px 0px 0px 8px inset;*/
  transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s; }

@font-face {
  font-family: 'aheadicon';
  src: url("../webfonts/aheadicon.ttf?e0o761") format("truetype"), url("../webfonts/aheadicon.woff?e0o761") format("woff"), url("../webfonts/aheadicon.svg?e0o761#aheadicon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="aheadicon-"], [class*=" aheadicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'aheadicon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.aheadicon-join:before {
  content: "\e938"; }

.aheadicon-transform:before {
  content: "\e935"; }

.aheadicon-group:before {
  content: "\e936"; }

.aheadicon-empty-dash-alt:before {
  content: "\e937"; }

.aheadicon-multi-select:before {
  content: "\e930"; }

.aheadicon-date-range:before {
  content: "\e931"; }

.aheadicon-kpi-chart:before {
  content: "\e932"; }

.aheadicon-settings-alt:before {
  content: "\e933"; }

.aheadicon-nested:before {
  content: "\e92e"; }

.aheadicon-resize-sw:before {
  content: "\e92f"; }

.aheadicon-task:before {
  content: "\e934"; }

.aheadicon-template:before {
  content: "\e926"; }

.aheadicon-select:before {
  content: "\e927"; }

.aheadicon-iteration:before {
  content: "\e928"; }

.aheadicon-format:before {
  content: "\e929"; }

.aheadicon-sort:before {
  content: "\e92a"; }

.aheadicon-aggregate:before {
  content: "\e92b"; }

.aheadicon-calc:before {
  content: "\e92c"; }

.aheadicon-filter:before {
  content: "\e92d"; }

.aheadicon-label:before {
  content: "\e920"; }

.aheadicon-gauge:before {
  content: "\e921"; }

.aheadicon-bar-chart:before {
  content: "\e922"; }

.aheadicon-image:before {
  content: "\e923"; }

.aheadicon-dropdown:before {
  content: "\e924"; }

.aheadicon-date:before {
  content: "\e925"; }

.aheadicon-data-to-widget:before {
  content: "\e91e"; }

.aheadicon-empty-dash:before {
  content: "\e91f"; }

.aheadicon-another-widget:before {
  content: "\e917"; }

.aheadicon-existing-task:before {
  content: "\e918"; }

.aheadicon-manual-input:before {
  content: "\e919"; }

.aheadicon-player-data:before {
  content: "\e91a"; }

.aheadicon-dashboard-data:before {
  content: "\e91b"; }

.aheadicon-imported-data:before {
  content: "\e91c"; }

.aheadicon-search:before {
  content: "\e91d"; }

.aheadicon-customize:before {
  content: "\e916"; }

.aheadicon-checkmark:before {
  content: "\e915"; }

.aheadicon-help:before {
  content: "\e914"; }

.aheadicon-drag:before {
  content: "\e912"; }

.aheadicon-logout:before {
  content: "\e913"; }

.aheadicon-access:before {
  content: "\e907"; }

.aheadicon-caret:before {
  content: "\e908"; }

.aheadicon-dashboards:before {
  content: "\e909"; }

.aheadicon-delete:before {
  content: "\e90a"; }

.aheadicon-dots:before {
  content: "\e90b"; }

.aheadicon-eye:before {
  content: "\e90c"; }

.aheadicon-list:before {
  content: "\e90d"; }

.aheadicon-playlists:before {
  content: "\e90e"; }

.aheadicon-settings:before {
  content: "\e90f"; }

.aheadicon-thumbs:before {
  content: "\e910"; }

.aheadicon-users:before {
  content: "\e911"; }

.aheadicon-arrow:before {
  content: "\e900"; }

.aheadicon-edit:before {
  content: "\e901"; }

.aheadicon-password:before {
  content: "\e902"; }

.aheadicon-plus:before {
  content: "\e903"; }

.aheadicon-user:before {
  content: "\e904"; }

.aheadicon-warning:before {
  content: "\e905"; }

.aheadicon-x:before {
  content: "\e906"; }

@media only screen and (min-width: 1400px) {
  .dashthumbs .dashthumb {
    width: 16.66667%;
    padding: 5px; } }

@media only screen and (min-width: 993px) {
  .modal-xl {
    max-width: 1000px; } }

@media only screen and (max-width: 992px) {
  body {
    padding-top: 50px; }
  .mobilebar {
    display: block; }
  .side {
    position: fixed;
    left: -230px;
    top: 0;
    bottom: 0;
    transition: all 220ms cubic-bezier(0.39, 0.575, 0.565, 1); }
    .side::before {
      content: "";
      box-shadow: 2px 0 20px rgba(47, 55, 66, 0.1);
      transition: .2s all;
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0; }
  .side__branding {
    padding-top: 40px; }
  .showside .side {
    left: 0; }
    .showside .side::before {
      opacity: 1; }
  .main {
    overflow: hidden; }
  .main__header {
    border-left: 0; }
  .btn-closeside {
    display: inline-block; }
  .pane--fixed {
    left: 0;
    top: 1px; }
  .racepage {
    padding-top: 0; }
  .modal-xl {
    max-width: 95%; }
  .list-scores .list-scores__title .list-scores__name {
    font-size: 1.1em;
    padding-left: 20px; } }

@media only screen and (max-width: 830px) {
  .list-scores p {
    font-size: 1.05em; } }

@media only screen and (max-width: 1150px) {
  .userslist--dash .useritem {
    flex-wrap: wrap;
    padding-right: 15px;
    padding-bottom: 30px; }
  .userslist--dash .useritem__actions {
    position: absolute;
    right: 12px;
    bottom: 15px;
    padding-right: 8px; }
  .userslist--dash .useritem__override {
    position: absolute;
    right: 150px;
    bottom: 20px; }
  .scoreboard__user .avatar {
    width: 56px;
    height: 56px;
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.25); }
  .scoreboard__nav .btn {
    top: auto;
    transform: none;
    bottom: 15px;
    width: 38px;
    height: 38px; }
  .scoreboard__nav .btn-prev {
    left: auto;
    right: 51%; }
  .scoreboard__nav .btn-next {
    right: auto;
    left: 51%; } }

@media only screen and (max-width: 1024px) {
  .dashthumbs .dashthumb {
    width: 33.3333%; }
  .pane__ctrl {
    flex-direction: column; }
    .pane__ctrl > * {
      width: 100%; }
  .pane__head--static {
    padding: 10px 12px; }
    .pane__head--static .pane__ctrl {
      flex-direction: row;
      align-items: center; }
      .pane__head--static .pane__ctrl > * {
        width: auto;
        flex: 1 1 auto; }
      .pane__head--static .pane__ctrl .pane__actions {
        padding-top: 0;
        text-align: right; }
        .pane__head--static .pane__ctrl .pane__actions .btn--wide,
        .pane__head--static .pane__ctrl .pane__actions .btn--halfwide {
          min-width: 80px; }
  .pane__actions {
    display: block;
    justify-content: flex-start;
    padding-top: 12px; }
    .pane__actions .btn {
      margin-left: 0;
      margin-right: 4px; }
    .pane__actions .btn-save {
      float: right; }
  .pane--dashboards .pane__head {
    padding-top: 15px; }
  .useritem__actions .btn-action {
    background-color: rgba(47, 55, 66, 0.07); }
  .userslist--dash .useritem {
    padding-bottom: 12px; }
  .userslist--dash .useritem__title {
    flex: 0 0 auto;
    width: calc(100% - 200px); }
  .userslist--dash .useritem__score,
  .userslist--dash .useritem__points {
    width: 50%; }
  .userslist--dash .useritem__points {
    padding-left: 15px; }
  .userslist--dash .useritem__actions {
    top: 15px;
    bottom: auto; }
  .userslist--dash .useritem__override {
    top: 12px;
    bottom: auto; }
  .userslist--dash .useritem__id {
    position: absolute;
    top: 14px;
    right: 230px; } }

@media only screen and (min-width: 768px) {
  .content--vertcenter {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center; }
  .form--loginsetup .form-group--innerlabel > label {
    top: 15px; }
  .form--loginsetup .form-control {
    height: 48px; }
  .form--loginsetup .btn--primary {
    padding-top: 16px;
    padding-bottom: 16px; } }

@media only screen and (min-width: 1400px) {
  .content--vertcenter {
    height: 84%; } }

@media only screen and (max-width: 768px) {
  body {
    font-size: 15px; }
  .checkbox.checbox-switch label .dot::before {
    top: -3px; } }

@media screen and (max-width: 767px) {
  .btn {
    font-size: .85em; }
  .main__header {
    height: 50px;
    padding: 10px 12px; }
  .main__stats p {
    font-size: .78em; }
  .main__actions .btn-new {
    padding-left: 11px;
    padding-right: 11px; }
  .btn-group--view {
    margin-right: 4px; }
  .main__body {
    padding: 20px 12px; }
  .btn-dashgroup {
    flex-wrap: wrap; }
    .btn-dashgroup p {
      text-align: left;
      margin-top: 5px; }
  .btn-dashgroup__stats {
    width: 100%; }
  .dashthumbs .dashthumb {
    width: 50%; }
  .dashthumb__title h5 {
    font-size: .95em; }
  .dashthumb__actions {
    padding: 8px 8px; }
  .new {
    padding: 25px 12px; }
  .new__blocks {
    margin: 0 -2px; }
    .new__blocks > * {
      margin: 0 2px;
      padding: 18px 9px; }
  .btn-dashgroup {
    padding: 12px;
    padding-left: 40px; }
    .btn-dashgroup::before {
      left: 16px;
      font-size: .75em; }
  .pane--dashboards .pane__head {
    padding: 15px; }
  .pane--dashboards .pane__ctrl .btn {
    margin-top: 2px;
    margin-bottom: 2px; }
  .pane--dashboards .pane__body {
    padding: 15px; }
  .userslist--dash .useritem {
    padding-top: 30px;
    padding-bottom: 30px; }
  .userslist--dash .useritem__drag {
    align-items: flex-start;
    margin-top: -8px; }
  .userslist--dash .useritem__check {
    position: absolute;
    top: 18px;
    left: 25px; }
  .userslist--dash .useritem__pic {
    text-align: center;
    width: 100%;
    padding-left: 2px; }
    .userslist--dash .useritem__pic img {
      height: 90px;
      width: auto; }
  .userslist--dash .useritem__title {
    text-align: center;
    width: 100%; }
  .userslist--dash .useritem__id {
    right: 150px;
    top: 17px;
    text-align: right;
    padding: 0; }
  .userslist--dash .useritem__title {
    padding-bottom: 15px; }
  .userslist--dash .useritem__actions {
    top: 18px; }
  .userslist--dash .useritem__override {
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    padding: 8px 0; }
  .playered__side {
    width: 120px; }
    .playered__side .dropdown-toggle, .playered__side .dropdown-toggle:active, .playered__side .dropdown-toggle:focus {
      margin: 5px;
      width: calc(100% - 10px);
      padding: 10px 5px; }
      .playered__side .dropdown-toggle::after, .playered__side .dropdown-toggle:active::after, .playered__side .dropdown-toggle:focus::after {
        right: 0px; }
      .playered__side .dropdown-toggle::before, .playered__side .dropdown-toggle:active::before, .playered__side .dropdown-toggle:focus::before {
        width: 5px; }
    .playered__side .dropdown-menu {
      width: 150px; }
  .dropdown--colors .dropdown-menu {
    width: 170px;
    padding: 20px 20px; }
  .scoreboard__logo {
    left: 10px;
    width: 60px;
    height: 60px;
    top: 50%; }
  .scoreboard__aheadlogo {
    right: 10px; }
    .scoreboard__aheadlogo img {
      width: 75px; }
  .list-scores > li {
    padding: 8px 10px; }
  .list-scores p {
    font-size: .8em; }
  .list-scores .avatar {
    width: 28px;
    height: 28px;
    margin-right: 9px; }
  .list-scores .no {
    width: 20px;
    height: 20px;
    line-height: 18px;
    margin-right: 4px;
    border-width: 1px; }
  .list-scores .list-scores__name {
    padding-right: 8px; }
  .list-scores .list-scores__sec {
    display: block; }
  .list-scores .list-scores__dept {
    display: none; }
  .list-scores .list-scores__val,
  .list-scores .list-scores__target {
    width: 20%;
    padding-right: 8px; }
  .list-scores .list-scores__perc {
    width: 34px; }
  .list-scores .list-scores__head p {
    font-size: 12px; }
  .list-scores .list-scores__trend {
    width: 25px; }
    .list-scores .list-scores__trend .aheadicon-arrow {
      width: 20px;
      height: 20px; }
  .list-scores--altanim > li {
    padding-left: 6px;
    padding-right: 6px; }
  .list-racers > li {
    width: 50px; }
  .scoreboard--user .scoreboard__aheadlogo {
    right: 0;
    bottom: 105%;
    top: auto;
    transform: none; }
  .scoreboard--user .scoreboard__head {
    padding: 20px; }
    .scoreboard--user .scoreboard__head h2 {
      max-width: inherit;
      font-size: 1.4em; }
      .scoreboard--user .scoreboard__head h2 span {
        font-size: 15px; } }

@media screen and (max-width: 600px) {
  .useritem {
    flex-wrap: wrap;
    position: relative; }
    .useritem:hover {
      z-index: 2; }
  .useritem__pic {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 0;
    text-align: center; }
  .useritem__actions {
    position: absolute;
    right: 0;
    top: 0;
    padding: 14px 12px; }
    .useritem__actions .btn-action {
      padding: 5px 7px;
      font-size: 14px; }
  .useritem__title {
    width: 100%;
    padding: 5px 14px;
    text-align: center; }
    .useritem__title h5 {
      display: block;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
  .useritem__type {
    width: 100%;
    text-align: center;
    padding-bottom: 20px; }
  .userslist--dash .useritem__actions {
    top: 5px; }
  .userslist--play .useritem {
    padding: 12px; }
  .userslist--play .useritem__drag {
    width: 20px; }
  .userslist--play .useritem__check {
    width: 20px;
    padding-left: 5px; }
  .userslist--play .useritem__pic {
    width: 100%;
    padding-left: 0; }
    .userslist--play .useritem__pic img {
      width: 100px;
      height: auto; }
  .userslist--play .useritem__title {
    padding-bottom: 12px; }
  .userslist--play .useritem__opts {
    width: 50%;
    padding-left: 3px; }
  .userslist--play .useritem__override {
    width: 50%;
    text-align: right;
    padding-right: 3px; }
  .playered__content-center .player {
    width: 200px; } }

@media screen and (max-width: 650px) {
  .pane__ctrl .pane__actions .btn {
    padding-left: 10px;
    padding-right: 10px; } }

@media screen and (min-width: 361px) {
  .main__stats p br {
    display: none; } }

@media screen and (max-width: 360px) {
  .btn-mobilemenu .title {
    font-size: 10px; }
  .main__body {
    padding: 16px 8px; }
  .new__blocks > * i {
    font-size: 1.4em; }
  .new__blocks > * h5 {
    margin: 6px 0; }
  .new__blocks > * p {
    font-size: .7em; }
  .useritem__actions > .btn-action {
    display: none; }
  .userslist--dash .useritem__actions .btn-delete {
    display: inline-block; }
  .scoreboard__logo {
    width: 46px;
    height: 46px; }
  .scoreboard__head h2 {
    font-size: 1.1em; }
  .scoreboard__aheadlogo img {
    width: 58px; }
  .list-scores > li {
    padding: 8px; }
  .list-scores p {
    font-size: .75em; }
  .list-scores .avatar {
    width: 22px;
    height: 22px;
    margin-right: 4px; }
  .list-scores__val,
  .list-scores__target {
    padding-right: 6px; }
  .list-scores__name {
    padding-right: 7px; } }

@media (min-width: 576px) {
  .modal-md {
    max-width: 760px; } }

@media screen and (max-width: 992px) and (max-height: 450px) {
  .scoreboard--user .scoreboard__head {
    padding-top: 14px;
    padding-bottom: 14px; }
  .scoreboard--user .scoreboard__aheadlogo {
    right: 15px;
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .scoreboard__user {
    position: absolute;
    left: 20px;
    margin: 0;
    display: flex;
    align-items: center;
    top: 50%;
    transform: translateY(-50%); }
    .scoreboard__user .avatar {
      box-shadow: 0 0 0 3px rgba(47, 55, 66, 0.35);
      width: 44px;
      height: 44px; }
    .scoreboard__user p {
      margin-bottom: 0;
      margin-left: 12px; }
  .list-scores > li {
    padding-top: 6px;
    padding-bottom: 6px; }
  .list-scores .list-scores__title {
    padding-top: 5px;
    padding-bottom: 5px; }
    .list-scores .list-scores__title .list-scores__name {
      font-size: .85em; } }
